// --------------------------------------------------------- REACT ------
import { React, useState, useEffect } from "react"
import emailValidator from 'email-validator'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Grid,    
    Paper,
    Stack,
    
    Drawer,
    
    Typography,
    Button,
    IconButton,
    
    TextField,    
    
    Alert,
    Collapse,

    CircularProgress,    
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI LAB ----
import {
    LoadingButton,    
} from '@mui/lab/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import { useAuthState } from '../authProvider';
import DialogChangePassword from './dialogchangepassword'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const API_URL_ME = "/api/me/"
// ----------------------------------------------------------------------
export default function DrawerAccount (props) {
    const {open, setOpen, userId, onSave, onClose } = props
    const {accessToken } = useAuthState()

    const elementDefault = {
        isInitializing: true
    }

    const formDefault = {
        disabled: false,                              
        mainButtonDisabled: true,
        mainButtonLoading: false,
        error: false,    
        errorText: "",
        errorEmail: false,
        errorEmailText: "",        
    }

    const formValueDefault = {
        name: "",
        email: "",        
    }
        
    const [element, setElement] = useState(elementDefault)
    const [form, setForm] = useState(formDefault)   
    const [data, setData] = useState(formValueDefault)
    const [dataDefault, setDataDefault] = useState({})

    const [dialogState, setDialogState] = useState({
        open: false,        
    })
    
    useEffect(() => {
        if (open && userId != "" && userId != null) {
            setElement(elementDefault)
            fetch("/api/me/", {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
            })
            .then(data=>{                
                return data.json ()
            })                
            .then((data) => {                            
                if (data.error == null) {                    
                    setDataDefault(data)
                    setData(data)          
                    setElement({ ...element, isInitializing: false })                          
                } else {              
                    throw new Error (data.error.code)                
                }
            })            
            .catch((error) => {
                handleError (error)
            })        
        }                
    }, [open])

    const handleError = (error) => {
        setElement({ ...element, isInitializing: false })                          

        if (error.message == "ER_USER_NOT_FOUND")
            setForm({ ...form, error: true, errorText: "User not found." })
        else if (error.message == "ER_USER_EMAIL_TAKEN")
            setForm({ ...form, error: true, errorText: "Email is allready taken." })
        else
            setForm({ ...form, error: true, errorText: "An unknown error occurred." })                       

        // console.error(error)
    }

    const handleClose = (event, reason) => {
        
        if (reason && reason == "backdropClick") 
            return 

        if (reason && reason == "escapeKeyDown") 
            return
        
        setForm(formDefault)
        setData(formValueDefault)
        
        setOpen(false)

        if (onClose)
            onClose ()
    }

    const handleSave = () => {
        setForm({ ...form, mainButtonLoading: true })

        fetch('/api/me/', {
            method: 'PATCH', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
            body: JSON.stringify(data),
        })
        .then((data) => {
            if (data.ok) {                
                if (onSave)
                    onSave ()
                handleClose ()                               
            } else {
                return data.json ()            
            }
        })        
        .then((data) => {
            if (data != null)
                throw new Error (data.error.code)                
        })
        .catch((error) => {       
            handleError (error)                     
        })            
    }

    const onChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value
        
        let dataChanged = true
        let emailIsValid = false        

        let formState = {...form}

        formState.saveError = false
        formState.saveErrorText = ""
                
        // Validate: DATACHANGE
        if (JSON.stringify({...dataDefault}) === JSON.stringify({ ...data, [id]: value }))
            dataChanged = false

        // Validate: EMAIL
        if (emailValidator.validate({ ...data, [id]: value }.email)) {
            emailIsValid = true
            formState.errorEmail = false
            formState.errorEmailText = ""            
        } else if ({ ...data, [id]: value }.email.length > 0) {            
            formState.errorEmail = true
            formState.errorEmailText = "E-Mail is not valid"            
        }
                
        formState.mainButtonDisabled = !(dataChanged && emailIsValid)

        setForm(formState)
        setData({ ...data, [id]: value })
        
        return true
    }

    const handleChangePassword = () => {
        setDialogState({
            open: true
        })        
    }

    return (
        <Drawer open={open} anchor={'right'} style={{ zIndex: 1300 }}>
            {element.isInitializing ? 
                <Box style={{ height: '100%', width: 600, display: 'flex', alignItems: 'center',   justifyContent: 'center',}}>
                    <CircularProgress style={{width: '50px', height: '50px'}}/>
                </Box>                  
            :                   
                <Box sx={{ m: 2, width: { xs: 0, md: 600 } }} role="permanent">                    
                    <DialogChangePassword open={dialogState.open} dialogState={dialogState} setDialogState={setDialogState}/> 

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Collapse in={form.error}>
                                <Alert variant="filled" severity="error" 
                                    action={
                                        <IconButton aria-label="close" color="inherit" size="small"
                                            onClick={() => {
                                                setForm({ ...form, error: false })
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    {form.errorText}
                                </Alert>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2}}>
                                <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                                    Login
                                </Typography>                            
                                <TextField                                                                               
                                    margin="dense"
                                    id="username"
                                    label="Username"            
                                    fullWidth                    
                                    variant="standard"
                                    autoComplete="new-password"
                                    onChange={onChange}
                                    error={form.usernameError}
                                    helperText={form.usernameErrorText}
                                    value={data.username}
                                    disabled
                                />                              
                            </Paper>
                        </Grid>                         
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2}}>
                                <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                                    Details
                                </Typography>
                                <TextField                    
                                    margin="dense"
                                    id="name"
                                    label="Name"            
                                    fullWidth
                                    variant="standard"  
                                    onChange={onChange}                  
                                    value={data.name}
                                    disabled={form.disabled}
                                />
                                <TextField       
                                    required             
                                    margin="dense"
                                    id="email"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                    autoComplete="off"
                                    onChange={onChange}                    
                                    error={form.errorEmail}
                                    helperText={form.errorEmailText}
                                    value={data.email}
                                    disabled={form.disabled}
                                /> 
                            </Paper> 
                        </Grid>                                                        
                        <Grid item xs={6}>
                            <Box display="flex">
                                <Stack direction="row" spacing={2}>
                                    <Button variant="contained" onClick={handleChangePassword}>Change password</Button>
                                </Stack>                   
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <Stack direction="row" spacing={2}>                                
                                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                                    <LoadingButton variant="contained" color="success" loading={form.mainButtonLoading} disabled={form.mainButtonDisabled} onClick={handleSave}>
                                        Save
                                    </LoadingButton>       
                                </Stack>                   
                            </Box>
                        </Grid>
                    </Grid>
                </Box>      
            }      
        </Drawer>
    )
}