// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Grid,
    Collapse,    
    Typography,
    Alert,
    Avatar, 
    Link,
    TextField,
    IconButton,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI LAB ----
import {LoadingButton} from '@mui/lab/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,
    LockOutlined as LockOutlinedIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import { authSignIn, useAuthDispatch } from '../authProvider'
// ----------------------------------------------------------------------

export default function Main(props) {
    const { settings } = props
    const [form, setForm] = useState({
        disabled: false,
        loading: false,
        mainButtonDisabled: true,
        error: false,    
        errorText: "",
    })    

    const dispatch = useAuthDispatch()	

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        setForm({ ...form, loading: true, error: false, disabled: true })

        try {
            if (settings.useEmailAsUsername)
                await authSignIn(dispatch, {email: data.get('email'), password: data.get('password')})            
            else
                await authSignIn(dispatch, {username: data.get('username'), password: data.get('password')})            
        } catch (error) {
            handleError(error)
        }
    }

    const handleError = (error) => {
        setForm({ ...form, loading: false, error: false, disabled: false })

        if (error.message == "ER_INVALID_USERNAME_PASSWORD")
            setForm({ ...form, error: true, errorText: "Username or Password does not match." })
        else
            setForm({ ...form, error: true, errorText: "An unknown error occurred." })        
    }
    
    const handleOnChange = (event) => {
        const data = new FormData(event.currentTarget)

        if (settings.useEmailAsUsername) {
            if (data.get('email').length > 0 && data.get('password').length > 0)
                setForm({ ...form, mainButtonDisabled: false })                
            else
                setForm({ ...form, mainButtonDisabled: true })            
        } else {
            if (data.get('username').length > 0 && data.get('password').length > 0)
                setForm({ ...form, mainButtonDisabled: false })                
            else
                setForm({ ...form, mainButtonDisabled: true })            
        }
        
        
        
    }

    return (
        <React.Fragment>
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>               
            <Box component="form" noValidate onSubmit={handleSubmit} onChange={handleOnChange} sx={{ mt: 1 }}>
                <Collapse in={form.error}>
                    <Alert variant="filled" severity="error" 
                        action={
                            <IconButton aria-label="close" color="inherit" size="small"
                                onClick={() => {
                                    setForm({ ...form, error: false })
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {form.errorText}
                    </Alert>
                </Collapse>
                {settings.useEmailAsUsername
                ? 
                    <TextField                            
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        autoComplete="username"
                        autoFocus                            
                        disabled={form.disabled}                      
                    />
                :
                    <TextField                            
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus                            
                        disabled={form.disabled}                      
                    />
                }
                <TextField                            
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    disabled={form.disabled}                      
                />
                {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                /> */}
                <LoadingButton 
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={form.mainButtonDisabled || form.disabled}
                    loading={form.loading}  
                    >
                        Sign In
                </LoadingButton>                           
                <Grid container>
                    <Grid item xs>
                        <Link href="/forgotpassword" variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>                                
                    </Grid>
                </Grid>                        
            </Box>                
        </React.Fragment>
    )
}