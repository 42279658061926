// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect, useCallback, useMemo } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Collapse,    
    Box,
    Stack,
    Paper,
    Typography,
    Alert,
    Button,    
    TextField,            
    IconButton,    
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,
    SimpleUIBusy
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {API_URL_SIMPLECRM, functionalStateUpdateKeyValuePair, functionalStateUpdate} from '../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

const DEFAULT_VALUE_DASHBOARD = {
    name: "",    
}

const EditDashboard = (props) => {
    const {drawerState, setDrawerState, dashboardId, callback} = props

    const {accessToken} = SimpleUIAuthState()
    
    // #region FORM
    const formInitialState = useMemo(() =>  {return {
        isInitializing: true,        
        disabled: false,                              
        mainButtonDisabled: true,
        mainButtonLoading: false,        
        error: false,    
        errorText: ""        
    }}, [])

    const [formState, setFormState] = useState(formInitialState)    
    const [formInitialData, setFormInitialData] = useState()
    const [formData, setFormData] = useState(DEFAULT_VALUE_DASHBOARD)
   
    const errorCallback = useCallback((error) => {
        let errorText = ""
        switch (error.message) {
            default: 
                errorText = "Der opstod en uventet fejl."                
        }

        setFormState((prevState) => {return functionalStateUpdate(prevState, {isInitializing: false, createLoading: false, error: true, errorText: errorText})})
    }, [])  
    
    const onChangeCallback = useCallback((event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value

        setFormData((prevState) => {return functionalStateUpdateKeyValuePair(prevState, id, value)})

        return true
    }, [])

    const createCallback = useCallback (async (data) => {
        setFormState((prevState) => {return functionalStateUpdate(prevState, {mainButtonLoading: true})})

        try {            
            let fetchPost = await fetch(`${API_URL_SIMPLECRM.DASHBOARDS}`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(data)
            })

            if (!fetchPost.ok)
                throw new Error((await fetchPost.json()).error.code)
            
            const newFormData = await fetchPost.json()
            setFormInitialData((prevState) => {return functionalStateUpdate(prevState, structuredClone(newFormData))})

            if (callback)
                callback()

        } catch (error) {                      
            errorCallback(error)
        }        
    }, [accessToken, errorCallback, callback])

    const updateCallback = useCallback(async (data) => {
        setFormState((prevState) => {return functionalStateUpdate(prevState, {mainButtonLoading: true})})

        try {            
            let fetchPatch = await fetch(`${API_URL_SIMPLECRM.DASHBOARDS}${data.id}`, {
                method: 'PATCH', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(data)
            })  

            if (!fetchPatch.ok)
                throw new Error((await fetchPatch.json()).error.code)
            
            setFormInitialData((prevState) => {return functionalStateUpdate(prevState, structuredClone(data))})

            if (callback)
                callback()

        } catch (error) {
            errorCallback(error)
        }                    
    }, [accessToken, errorCallback, callback])

    const onClickMainButtonCallback = useCallback(() => {
        if (formData.id)
            updateCallback(formData)
        else 
            createCallback(formData)
    }, [formData, updateCallback, createCallback])

    const closeCallback = useCallback(() => {
        setFormState((prevState) => {return functionalStateUpdate(prevState, formInitialState)})
        setFormData((prevState) => {return functionalStateUpdate(prevState, DEFAULT_VALUE_DASHBOARD)})
        setDrawerState((prevState) => {return functionalStateUpdate(prevState, {open: false})})
    }, [formInitialState, setDrawerState])

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal

        const asyncFunction = async () => {
            if (dashboardId) {
                try {
                    let fetchGet = await fetch(`${API_URL_SIMPLECRM.DASHBOARDS}${dashboardId}`, {
                        method: 'GET',
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer "+ accessToken
                        },
                        signal: signal
                    })           
    
                    if (!fetchGet.ok)
                        throw new Error((await fetchGet.json()).error.code)
    
                    setFormInitialData(await fetchGet.json())
                } catch (error) {
                    errorCallback(error)
                }
            } else {            
                setFormInitialData(DEFAULT_VALUE_DASHBOARD)
            }
        }

        asyncFunction()

        return () => {controller.abort()}
    }, [drawerState.open, dashboardId, accessToken, errorCallback])
    
    useEffect(() => {
        if (!formInitialData)
            return

        setFormData(formInitialData)        
    }, [formInitialData])

    useEffect(() => {        
        if (!formInitialData)
            return

        if (!formData)
            return

        let valid = 0

        // Validate: CHANGED
        if (JSON.stringify(structuredClone(formInitialData)) === JSON.stringify(structuredClone(formData))) {
            valid++
        }

        // Validate: LABEL
        if ((formData?.name || "").length === 0) {
            valid++
        }

        setFormState((prevState) => {return functionalStateUpdate(prevState, {isInitializing: false, mainButtonLoading: false, mainButtonDisabled: !!(valid), error: false, errorText: ""})})
    }, [formData, formInitialData])
    // #endregion

    if (formState.isInitializing)
        return (SimpleUIBusy()) 
    
    return (
        <React.Fragment>
             {/* ALERT */}      
            <Collapse in={formState.error}>
                <Alert variant="filled" severity="error" 
                    action={
                        <IconButton aria-label="close" color="inherit" size="small"
                            onClick={() => {
                                setFormState({ ...formState, error: false })
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {formState.errorText}
                </Alert>
            </Collapse>  
            
            <Paper elevation={3} sx={{ p: 2}}>
                {/* HEADER */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="h6" sx={{mb: 1}}>
                        {formData.id ? "Edit Dashboard" : "Create Dashboard" }
                    </Typography>
                                    
                </Stack>                

                {/* LABEL */}
                <TextField sx={{mb: 1}}
                    required                                     
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth                                        
                    autoComplete="off"
                    value={formData.name ?? ""}
                    disabled={formState.disabled}
                    onChange={onChangeCallback}                    
                />
            </Paper>

            {/* BUTTONS */}          
            <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" onClick={closeCallback}>Close</Button>
                    <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={onClickMainButtonCallback}>
                        {formData.id ? "Save" : "Create" }
                    </LoadingButton>       
                </Stack>                   
            </Box>                
        </React.Fragment>
    )
}

export default EditDashboard