// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Collapse,    
    Box,
    Stack,
    Paper,
    Typography,
    Alert,
    Button,    
    TextField,            
    IconButton,    
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Tabs,
    Tab,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
    Edit as EditIcon,
    Delete as DeleteIcon,
    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,    
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {API_URL_SIMPLECRM, setStateKeyValuePair} from '../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

const DEFAULT_VALUE_PERMISSIONGROUP = {
    name: "",
    permissions: {} 
}

export default (props) => {
    const {drawerState, setDrawerState, permissionGroupId, callback} = props

    const {user, accessToken} = SimpleUIAuthState()
    
    // #region FORM
    const formDefaultState = {
        isInitializing: true,        
        disabled: false,                              
        mainButtonDisabled: true,
        mainButtonLoading: false,        
        error: false,    
        errorText: ""        
    }
    const [formState, setFormState] = useState(formDefaultState)       
    
    const [formDefaultData, setFormDefaultData] = useState()
    const [formData, setFormData] = useState()

    const [entityTypes, setEntityTypes] = useState([])
   
    useEffect(async () => {
        if (drawerState.open)
            setEntityTypes(await getEntityTypes())

        let data

        if (permissionGroupId) {            
            try {
                let fetchGet = await fetch(`${API_URL_SIMPLECRM.PERMISSIONGROUPS}${permissionGroupId}`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           

                if (!fetchGet.ok)
                    throw new Error((await fetchGet.json()).error.code)

                data = await fetchGet.json()
            } catch (error) {
                handleError(error)
            }
        } else {            
            data = structuredClone(DEFAULT_VALUE_PERMISSIONGROUP)
        }        

        for (const type of entityTypes) {
            if (!data.permissions[type.id]) {
                data.permissions[type.id] = {view: false, edit: false, create: false, delete: false}
            } else {
                if (!data.permissions[type.id].view)
                    data.permissions[type.id].view = false

                if (!data.permissions[type.id].edit)
                    data.permissions[type.id].edit = false

                if (!data.permissions[type.id].create)
                    data.permissions[type.id].create = false

                if (!data.permissions[type.id].delete)
                    data.permissions[type.id].delete = false
            }
        }

        setFormDefaultData(data)
    }, [drawerState.open])

    useEffect(async () => {        
        setFormData(formDefaultData)        
    }, [formDefaultData])

    useEffect(() => {
        if (!formData)
            return

        let valid = 0

        // Validate: CHANGED
        if (JSON.stringify(structuredClone(formDefaultData)) === JSON.stringify(structuredClone(formData))) {
            valid++
        }

        // Validate: LABEL
        if ((formData?.name || "").length == 0) {
            valid++
        }
                
        // // Validate: TYPE
        // if ((formData.type || "") == "") {
        //     valid++
        // }

        // // Validate: TEXTFIELD
        // if (formData.type == "textfield") {
        //     if ((formData.subType || "") == "")
        //         valid++
        // }

        // // Validate: UPLOAD
        // if (formData.type == "upload") {
        //     if ((formData.subType || "") == "")
        //         valid++
        // }

        // // VALIDATE: USER
        // if (formData.type == "user") {
        //     if (formData?.dataSource) {
        //         if ((formData.dataSource?.name || "") == "")
        //             valid++

        //         if ((formData.dataSource?.email || "") == "")
        //             valid++
        //     } 

        //     if ((formData.usertype || "") == "")
        //         valid++
        // }

        setFormState({...formState,
            isInitializing: false,
            mainButtonLoading: false,
            mainButtonDisabled: !!(valid),
            error: false,
            errorText: ""  
        })
        
    }, [formData])

    const handleOnClickMainButton = () => {
        if (formData.id)
            handleUpdate()
        else 
            handleCreate()
    }

    const handleOnChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value

        console.log (id +" "+ value)
        
        setFormData((prevState) => {return setStateKeyValuePair(prevState, id, value)})

        return true
    }

    const handleCreate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {            
            let fetchPost = await fetch(`${API_URL_SIMPLECRM.PERMISSIONGROUPS}`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(formData)
            })

            if (!fetchPost.ok)
                throw new Error((await fetchPost.json()).error.code)
            
            setFormDefaultData(structuredClone(await fetchPost.json()))

            if (callback)
                callback()

        } catch (error) {                      
            handleError(error)
        }        
    }

    const handleUpdate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {
            let fetchPatch = await fetch(`${API_URL_SIMPLECRM.PERMISSIONGROUPS}${formData.id}`, {
                method: 'PATCH', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(formData)
            })  

            if (!fetchPatch.ok)
                throw new Error((await fetchPatch.json()).error.code)
            
            setFormDefaultData(structuredClone(formData))

            if (callback)
                callback()

        } catch (error) {
            handleError(error)
        }                    
    }

    const handleClose = () => {
        setFormState(formDefaultState)
        setFormData(DEFAULT_VALUE_PERMISSIONGROUP)        
        setDrawerState({...drawerState, open: false})
    }

    const handleError = (error) => {
        setFormState({ ...formState, isInitializing: false })                     

        switch (error.message) {
            default: {
                setFormState({ ...formState, createLoading: false, error: true, errorText: "Der opstod en uventet fejl." })
            }
        }
    }
    // #endregion


    const getEntityTypes = async () => {
        let output = []    
        try {
            let fetchGet = await fetch(API_URL_SIMPLECRM.TYPES, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!fetchGet.ok)
                throw new Error((await fetchGet.json()).error.code)

            output = await fetchGet.json()

            console.log (output)
            
        } catch (error) {            
            handleError(error)
        }  

        return output
    }


    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    }     
    

    return (
        <React.Fragment>            
            <Collapse in={formState.error}>
                <Alert variant="filled" severity="error" 
                    action={
                        <IconButton aria-label="close" color="inherit" size="small"
                            onClick={() => {
                                setFormState({ ...formState, error: false })
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {formState.errorText}
                </Alert>
            </Collapse>  
            

            <Paper elevation={3} sx={{ p: 2}}>            
                {/* HEADER */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="h6" sx={{mb: 1}}>
                        {formData.id ? "Edit PermissionGroup" : "Create PermissionGroup" }
                    </Typography>
                                    
                </Stack>                

                {/* LABEL */}
                <TextField sx={{mb: 1}}
                    required                                     
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth                                        
                    autoComplete="off"
                    value={formData.name ?? ""}
                    disabled={formState.disabled}
                    onChange={handleOnChange}                    
                />
            </Paper>

            <TableContainer component={Paper}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="center" sx={{width: "20px"}}>View</TableCell>
                            <TableCell align="center" sx={{width: "20px"}}>Edit</TableCell>
                            <TableCell align="center" sx={{width: "20px"}}>Create</TableCell>
                            <TableCell align="center" sx={{width: "20px"}}>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entityTypes.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="center">
                                    <Checkbox 
                                        name={`${row.id}.view`}
                                        checked={formData.permissions[row.id]?.view ?? false}
                                        disabled={(formState.disabled)}
                                        onChange={(event) => {handleOnChange({target: {name: `permissions.${row.id}.view`, value: event.target.checked}})}}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <Checkbox 
                                        name={`${row.id}.edit`}
                                        checked={formData.permissions[row.id]?.edit ?? false}
                                        disabled={(formState.disabled)}
                                        onChange={(event) => {handleOnChange({target: {name: `permissions.${row.id}.edit`, value: event.target.checked}})}}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <Checkbox 
                                        name={`${row.id}.view`}
                                        checked={formData.permissions[row.id]?.create ?? false}
                                        disabled={(formState.disabled)}
                                        onChange={(event) => {handleOnChange({target: {name: `permissions.${row.id}.create`, value: event.target.checked}})}}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <Checkbox 
                                        name={`${row.id}.delete`}
                                        checked={formData.permissions[row.id]?.delete ?? false}
                                        disabled={(formState.disabled)}
                                        onChange={(event) => {handleOnChange({target: {name: `permissions.${row.id}.delete`, value: event.target.checked}})}}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Paper elevation={3} sx={{ p: 2}}>
                
            </Paper>
                                
            <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                    <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={handleOnClickMainButton}>
                        {formData.id ? "Save" : "Create" }
                    </LoadingButton>       
                </Stack>                   
            </Box>                
        </React.Fragment>
    )
}