// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Collapse,    
    Box,
    Stack,
    Paper,
    Typography,
    Alert,
    Button,    
    TextField,            
    IconButton,    
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Tabs,
    Tab,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,    
    SimpleUIChipBox,

    SimpleUIMaskedInputPattern,

    SimpleUICommonReactSwitch,
    SimpleUICommonReactCase,
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {API_URL_SIMPLECRM} from '../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >   
        <div style={{ display: value === index ? 'block': 'none'}}>  
            {children}
        </div>
      </div>
    )
}

const Switch = (props) => {    
    const { condition, children } = props

    const result = React.Children.toArray(children).find(child => {
        return child.props.value === condition
      })

    if (result)
        return result
    else
        return (<React.Fragment></React.Fragment>)
  }

const Case = ({ children, value }) => {
    return children
}

export default (props) => {
    const { drawerState, setDrawerState, parameterId, worker, callback } = props
    const { user, accessToken } = SimpleUIAuthState()

    // #region FORM
    const formDefaultState = {
        isInitializing: true,        
        disabled: false,                              
        mainButtonDisabled: true,
        mainButtonLoading: false,        
        error: false,    
        errorText: ""        
    }
    const formValueDefault = {}

    const [ formState, setFormState ] = useState(formDefaultState)       
    const [ formData, setFormData ] = useState(formValueDefault)
    const [ formDefaultData, setFormDefaultData ] = useState(formValueDefault)    
    const [ entityTypes, setEntityTypes ] = useState([])

    useEffect(() => {
        const asyncFunction = async () => {
            setEntityTypes(await getEntityTypes())
        }

        asyncFunction()
        
        if (parameterId) {
            // console.log (parameterId)
            // console.log (worker.parameters.find(w => w.id == parameterId))
            // const cloneField = () => {
                // let clone = structuredClone(type.layouts.find((layout) => layout.id === layoutId[0]))
                // let clone = structuredClone(type.fields[layoutId[0]])
                // clone.id = layoutId[0]
                // return clone
            // }

            setFormData(structuredClone(worker.parameters.find(w => w.id == parameterId)))
            // setContent(JSON.stringify(cloneField().content, null, " "))
            // setFormDefaultData(cloneField())
        }        
    }, [drawerState.open])

    useEffect(() => {
        let valid = 0

        // Validate: CHANGED
        if (JSON.stringify({...formDefaultData}) === JSON.stringify({ ...formData})) {
            valid++
        }

        // Validate: LABEL
        if ((formData.name || "").length == 0) {
            valid++
        }
                
        // Validate: TYPE
        if ((formData.type || "") == "") {
            valid++
        }

        // // Validate: TEXTFIELD
        // if (formData.type == "textfield") {
        //     if ((formData.subType || "") == "")
        //         valid++
        // }

        // // Validate: UPLOAD
        // if (formData.type == "upload") {
        //     if ((formData.subType || "") == "")
        //         valid++
        // }

        // // VALIDATE: USER
        // if (formData.type == "user") {
        //     if (formData?.dataSource) {
        //         if ((formData.dataSource?.name || "") == "")
        //             valid++

        //         if ((formData.dataSource?.email || "") == "")
        //             valid++
        //     } 

        //     if ((formData.usertype || "") == "")
        //         valid++
        // }

        setFormState({...formState,
            isInitializing: false,
            mainButtonLoading: false,
            mainButtonDisabled: !!(valid),
            error: false,
            errorText: ""  
        })
        
    }, [formData])

    const handleOnChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value

        // console.log (id +" "+ value)
        
        setFormData(prevState => {
            const newState = structuredClone(prevState)
            const path = id.split('.')
            const last = path.pop()            
            path.reduce((ac,a) => ac[a], newState)[last] = value

            // SOURCE
            if (
                (newState.type != prevState.type) ||
                (!newState.source)
            )
                newState.source = {}

            // SOURCE.TYPE
            if (newState?.source?.type != prevState?.source?.type)
                newState.source = {type: newState.source.type}
          
            return newState
        })

        return true
    }

    const handleOnClickMainButton = () => {
        callback({...formData})
        handleClose()        
    }

    const handleClose = () => {
        setFormState(formDefaultState)
        setFormData(formValueDefault)        
        setDrawerState({...drawerState, open: false})
    }

    const handleError = (error) => {
        setFormState({ ...formState, isInitializing: false })                     

        switch (error.message) {
            default: {
                setFormState({ ...formState, createLoading: false, error: true, errorText: "Der opstod en uventet fejl." })
            }
        }
    }
    // #endregion

    // #region TAB STATES
    // const [tab, setTab] = React.useState(0)    
    // const handleOnChangeTab = (event, newValue) => {
    //     setTab(newValue);
    // }
    
    // const [dialogEditAPICredentialsState, setDialogEditAPICredentialsState] = React.useState({
    //     open: false,
    //     data: null        
    // })

    // const dialogEditAPICrendentialsCallback = (data) => {
    //     let tempAPICredentials = [...formData.apiCredentials]
    //     let index = tempAPICredentials.findIndex(o => o.id == data.id);

    //     if (index > -1) 
    //         tempAPICredentials[index] = {...data}
    //     else 
    //         tempAPICredentials.push ({...data})
        
    //     setFormData({...formData, apiCredentials: tempAPICredentials})
    // }    

    // const handleOnAPICredentialAdd = (id) => {
    //     setDialogEditAPICredentialsState({...setDialogEditAPICredentialsState,
    //         open: true,
    //         data: {}
    //     })
    // }

    // const handleOnAPICredentialEdit = (id) => {
    //     setDialogEditAPICredentialsState({...setDialogEditAPICredentialsState,
    //         open: true,
    //         data: formData.apiCredentials.find(o => { return o.id === id})
    //     })
    // }

    // const handleOnAPICredentialDelete = (id) => {
    //     setFormData({...formData, apiCredentials: formData.apiCredentials.filter(o => { return o.id !== id})})
    // }

    // const handleonAPICredentialsCallback = (data) => {
    //     setFormData({...formData, apiCredentials: data})
    // }

    // const handleOnSelectionAPICredentials = (selection) => {}
    // const handleOnClickAwayAPICredentials = () => {}   

    // #endregion

    // #region FUNCTIONS
    const getEntityTypes = async () => {
        let output = []    
        try {
            let getTypes = await fetch(API_URL_SIMPLECRM.TYPES, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getTypes.ok)
                throw new Error((await getTypes.json()).error.code)

            output = await getTypes.json()                        
        } catch (error) {            
            handleError(error)
        }  

        return output
    }


    // #endregion

    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    }     
    
    return (
        <React.Fragment>            
            <Collapse in={formState.error}>
                <Alert variant="filled" severity="error" 
                    action={
                        <IconButton aria-label="close" color="inherit" size="small"
                            onClick={() => {
                                setFormState({ ...formState, error: false })
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {formState.errorText}
                </Alert>
            </Collapse>  
            
            <Paper elevation={3} sx={{ p: 2}}>
            
                {/* OPTIONS */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="h6" sx={{mb: 1}}>
                        {formData.id ? "Edit " : "Create " } Parameter
                    </Typography>
                
                    {/* <FormGroup row={true}>
                        <FormControlLabel control={
                            <Checkbox                                            
                                checked={!!formData.default ?? false}
                                disabled={
                                    (formState.disabled)                                                                        
                                }
                                onChange={(event) => {handleOnChange({target: {name: "default", value: event.target.checked}})} }
                            />
                        } label="Default" />                        
                    </FormGroup>   */}
                </Stack>                

                 {/* ID */}
                 {formData.id && (
                    <TextField sx={{mb: 1}}
                        margin="dense"                    
                        label="Id"
                        fullWidth                    
                        value={formData.id ?? ""}
                        InputProps={{
                            readOnly: true,
                        }}                    
                    />
                )}
                
                {/* Name */}
                <TextField sx={{mb: 1}}
                    required                                     
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                               
                    autoComplete="off"
                    value={formData.name ?? ""}
                    disabled={formState.disabled}
                    onChange={handleOnChange}

                    InputProps={{   
                        inputComponent: SimpleUIMaskedInputPattern,
                        inputProps: {
                            pattern: /^[A-Za-z_]+$/,
                            maxLength: 32
                        }
                    }}
                />

                {/* Type */}
                <FormControl sx={{mt: 1, mb: 1}}
                    fullWidth 
                    required 
                >
                    <InputLabel>Type</InputLabel>
                    <Select                                  
                        label="Type"
                        name="type"
                        value={formData.type ?? ""}
                        onChange={handleOnChange}                        
                        disabled={(
                            (formState.disabled)
                        )}
                    >       
                        <MenuItem key={1} value={"string"}>String</MenuItem>
                        {/* <MenuItem key={2} value={"number"}>Number</MenuItem> */}
                        {/* <MenuItem key={3} value={"bool"}>Bool</MenuItem> */}
                        <MenuItem key={4} value={"file"}>File</MenuItem>
                        <MenuItem key={5} value={"entity"}>Entity</MenuItem>
                    </Select>
                </FormControl>                               

                <SimpleUICommonReactSwitch condition={formData.type}>    
                    <SimpleUICommonReactCase value={"string"}>
                        {/* Source */}
                        <FormControl sx={{mt: 1, mb: 1}}
                            fullWidth 
                            required 
                        >
                            <InputLabel>Source</InputLabel>
                            <Select
                                label="Source"
                                name="source.type"
                                value={formData?.source?.type ?? ""}
                                onChange={handleOnChange}
                                disabled={(                                    
                                    (formState.disabled)
                                )}
                            >                                       
                                <MenuItem key={1} value={"user"}>User</MenuItem>
                                <MenuItem key={2} value={"predefined"}>Predefined</MenuItem>                                
                            </Select>
                        </FormControl>    

                        <SimpleUICommonReactSwitch condition={formData?.source?.type}>
                            <SimpleUICommonReactCase value={"predefined"}>
                                {/* Source Value */}
                                <TextField sx={{mb: 1}}
                                    required                                     
                                    margin="dense"
                                    id="source.value"
                                    label="Value"
                                    fullWidth                                        
                                    autoComplete="off"
                                    value={formData?.source?.value ?? ""}
                                    disabled={formState.disabled}
                                    onChange={handleOnChange}                    
                                />                           
                            </SimpleUICommonReactCase>
                        </SimpleUICommonReactSwitch>
                    </SimpleUICommonReactCase>

                    <SimpleUICommonReactCase value={"file"}>
                        {/* Source */}
                        <FormControl sx={{mt: 1, mb: 1}}
                            fullWidth 
                            required 
                        >
                            <InputLabel>Source</InputLabel>
                            <Select
                                label="Source"
                                name="source.type"
                                value={formData?.source?.type ?? ""}
                                onChange={handleOnChange}
                                disabled={(                                    
                                    (formState.disabled)
                                )}
                            >                                       
                                <MenuItem key={1} value={"upload"}>Upload</MenuItem>
                                <MenuItem key={2} value={"path"}>Path</MenuItem>                                
                            </Select>
                        </FormControl>    

                        <SimpleUICommonReactSwitch condition={formData?.source?.type}>
                            <SimpleUICommonReactCase value={"upload"}>
                                {/* Source Mimetypes */}
                                <SimpleUIChipBox 
                                    name="source.mimetypes" 
                                    label={"Mimetypes"} 
                                    freeSolo
                                    value={formData?.source?.mimetypes ?? []}
                                    disabled={formState.disabled}                                                        
                                    onChange={handleOnChange}
                                
                                />
                            </SimpleUICommonReactCase>
                            <SimpleUICommonReactCase value={"path"}>
                                {/* Source Path */}
                                <TextField sx={{mb: 1}}
                                    required                                     
                                    margin="dense"
                                    id="source.path"
                                    label="Path"
                                    fullWidth                                        
                                    autoComplete="off"
                                    value={formData?.source?.path ?? ""}
                                    disabled={formState.disabled}
                                    onChange={handleOnChange}                    
                                />
                           
                            </SimpleUICommonReactCase>
                        </SimpleUICommonReactSwitch>
                    </SimpleUICommonReactCase>

                    <SimpleUICommonReactCase value={"entity"}>
                        {/* Source */}
                        <FormControl sx={{mt: 1, mb: 1}}
                            fullWidth 
                            required 
                        >
                            <InputLabel>Source</InputLabel>
                            <Select
                                label="Source"
                                name="source.type"
                                value={formData?.source?.type ?? ""}
                                onChange={handleOnChange}
                                disabled={(                                    
                                    (formState.disabled)
                                )}
                            >                                       
                                <MenuItem key={1} value={"new"}>Create Entity</MenuItem>
                                <MenuItem key={2} value={"temporary"}>Create Temporary Entity</MenuItem>                                
                                <MenuItem key={3} value={"select"}>Select Entity</MenuItem>
                                <MenuItem key={4} value={"predefined"}>Predefined Entity</MenuItem>
                            </Select>
                        </FormControl>      

                        <SimpleUICommonReactSwitch condition={formData?.source?.type}>
                            <SimpleUICommonReactCase value={["new", "temporary", "select"]}>
                                {/* Source EntityType */}
                                <FormControl sx={{mt: 1, mb: 1}}
                                        fullWidth 
                                        required 
                                    >
                                    <InputLabel>EntityType</InputLabel>
                                    <Select                                  
                                        label="EntityType"
                                        name="source.entityType"                                        
                                        value={formData?.source?.entityType ?? []}
                                        onChange={handleOnChange}                        
                                        disabled={(                                            
                                            (formState.disabled)
                                        )}
                                    >       
                                        {entityTypes.map(function (entityType, i)  {
                                            if (                                            
                                                (entityType.parent === null)
                                            ) {
                                                return (
                                                    <MenuItem key={i} value={entityType.id}>{entityType.name}</MenuItem>
                                                )
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                            </SimpleUICommonReactCase>

                            <SimpleUICommonReactCase value={"predefined"}>
                                {/* Source EntityId */}
                                <TextField sx={{mb: 1}}
                                    required                                     
                                    margin="dense"
                                    id="source.entityId"
                                    label="EntityId"
                                    fullWidth                                        
                                    autoComplete="off"
                                    value={formData?.source?.entityId ?? ""}
                                    disabled={formState.disabled}
                                    onChange={handleOnChange}                    
                                />
                            </SimpleUICommonReactCase>
                        </SimpleUICommonReactSwitch>      
                                        
                    </SimpleUICommonReactCase>
                </SimpleUICommonReactSwitch>                
            </Paper>
                                
            <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                    <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={handleOnClickMainButton}>
                        {formData.id ? "Apply" : "Add" }
                    </LoadingButton>       
                </Stack>                   
            </Box>                
        </React.Fragment>
    )
}