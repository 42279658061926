// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    TextField,
    Typography,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUICommonInterpolate
} from './../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

export default (props) => {
    const value = SimpleUICommonInterpolate(props.type.fields[props.fieldId].format, props.data)

    return (   
        <Typography>
            {value}
        </Typography>     
        // <TextField sx={{ mb: 1 }}
        //     id={props.fieldId}

        //     label={props.type.fields[props.fieldId].label}

        //     value={value}

        //     required={false}
        //     disabled={true}
            
        //     variant="outlined"
        //     margin="dense"            
        //     fullWidth={true}
        // />
    )
}