import * as React from 'react'

const Switch = (props) => {    
    const { condition, children } = props

    const result = React.Children.toArray(children).find(child => {
        if (Array.isArray(child.props.value)) {
            if (child.props.value.find(value => value === condition))
                return true
        } else {
            if (child.props.value === condition)
                return true            
        }        

        return false
    })

    if (!result)
        return (<React.Fragment></React.Fragment>)
        
    return result
}

const Case = ({ children, value }) => {
    if (!children)
        return (<React.Fragment></React.Fragment>)

    return children
}

export {
    Switch,
    Case
}