// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

export default (props) => {   
    const {dialogState, setDialogState } = props

    const handleAccept = () => {
        setDialogState({ ...dialogState, open: false })
        
        if (dialogState.onConfirm != null)
            dialogState.onConfirm ()        
    }
    
    const handleDecline = () => {        
        setDialogState({ ...dialogState, open: false })
        
        if (dialogState.onDecline != null)
            dialogState.onDecline ()        
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return   

        handleDecline()
    }
    
    return (
        <Dialog 
            open={dialogState.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dialogState.title}
            </DialogTitle>
            <DialogContent>
                {dialogState.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDecline}>{dialogState.button1Text}</Button>
                <Button onClick={handleAccept} autoFocus>{dialogState.button2Text}</Button>
            </DialogActions>
        </Dialog>
    )
}
