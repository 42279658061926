// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    TextField,                
    FormControl,
    InputLabel,
    Select,
    MenuItem,    
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,    

    SimpleUICommonReactSwitch,
    SimpleUICommonReactCase,

} from './../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {API_URL_SIMPLECRM} from '../../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const { type, data, setData, formState, setFormState, onError } = props
    const { user, accessToken } = SimpleUIAuthState()

    const [ formData, setFormData ] = useState(structuredClone(data))

    const [ entityTypes, setEntityTypes ] = useState([])

    useEffect(async () => {
        // setEntityTypes(await getEntityTypes())
    }, [])

    useEffect(() => {
        let valid = 0

        // Validate: CHANGED
        // if (JSON.stringify({...formDefaultData}) === JSON.stringify({ ...formData})) {
        //     valid++
        // }

        // VALIDATE: ENTITIES
        // if (formData.subType == "entities") {
        //     for (const element of formData.config.allowedTypes) {                            
        //         // if (!formData.config.refs[element])
        //             // valid++
        //     }
        // }

        // VALIDATE: JSON
        // if (formData.subType == "json") {
        //     try {
        //         JSON.parse(formData?.config?.jsonSchema)
        //     } catch (error) {
        //         valid++
        //     }
        // }

        if (!(valid)) {
            setData(structuredClone(formData))
        } else {
            setFormState({...formState,
                mainButtonDisabled: !!(valid)
            })
        }

    }, [formData])

    const handleOnChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value

        // console.log (id +" "+ value)

        setFormData(prevState => {
            const newState = structuredClone(prevState)

        //     // SUBTYPE CHANGE
        //     if (newState.subType != prevState.subType) {
        //         newState.config = {}
        //         newState.required = false
        //         newState.unique = false
        //     }

            const path = id.split('.')
            const last = path.pop()            
            path.reduce((ac,a) => ac[a], newState)[last] = value

        //     if (                
        //         (newState.subType == "entities")         
        //     ) {
        //         newState.config.allowedTypes = ((!newState.config.allowedTypes) ? [] : newState.config.allowedTypes)
        //         newState.config.refs = ((!newState.config.refs) ? {} : newState.config.refs)

        //         const newRefs = {}
        //         for (const id of newState.config.allowedTypes) {
        //             if (newState.config.refs[id])
        //                 newRefs[id] = newState.config.refs[id]
        //         }
        //         newState.config.refs = newRefs
        //     }

            return newState
        })

        return true
    }      

    const getEntityTypes = async () => {
        let output = []    
        try {
            let getTypes = await fetch(API_URL_SIMPLECRM.TYPES, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getTypes.ok)
                throw new Error((await getTypes.json()).error.code)

            output = await getTypes.json()

            // console.log (formData)

            // const index = output.findIndex(i => i.id === type.id)
            // if (index > -1)
            //     output.splice(index, 1)

        } catch (error) {            
            onError(error)
        }  

        console.log (output)

        return output
    }
           
    return (
        <React.Fragment>
                {/* SUBTYPE */}                
                <FormControl sx={{mt: 1, mb: 1}}
                    fullWidth 
                    required 
                >
                    <InputLabel>Subtype</InputLabel>
                    <Select                                  
                        label="subType"
                        name="subType"
                        value={formData.subType ?? ""}
                        onChange={handleOnChange}
                        disabled={
                            (formState.disabled) ||
                            (!!formData.id)                               
                        }
                    >       
                        <MenuItem key={1} value={"plaintext"}>Plaintext</MenuItem>
                        <MenuItem key={2} value={"html"}>HTML</MenuItem>
                    </Select>
                </FormControl>
                   
                {/* CONFIG */}
                <SimpleUICommonReactSwitch condition={formData.subType}>
                    <SimpleUICommonReactCase value={"entities"}>
                        <FormControl sx={{mt: 1, mb: 1}}
                            fullWidth 
                            required 
                        >
                            <InputLabel>EntityType</InputLabel>
                            <Select                                  
                                label="EntityType"
                                name="config.allowedTypes"
                                multiple
                                value={formData?.config?.allowedTypes ?? []}
                                onChange={handleOnChange}                        
                                disabled={(                                            
                                    (formState.disabled)
                                )}
                            >       
                                {entityTypes.map(function (entityType, i)  {
                                     if (
                                        (entityType.id != type.id) &&
                                        (entityType.parent === null || entityType.parent === type.id)
                                    ) {
                                        return (
                                            <MenuItem key={i} value={entityType.id}>{entityType.name}</MenuItem>
                                        )
                                    }
                                })}                                        
                            </Select>
                        </FormControl>
                        
                        {(formData?.config?.allowedTypes ?? []).map(function (item, i)  {
                            const entityType = entityTypes.find(i => i.id == item)

                            if (!entityType)
                                return (<React.Fragment></React.Fragment>)

                            if (entityType.parent === null) {

                                return (
                                    <FormControl sx={{mt: 1, mb: 1}}
                                        fullWidth 
                                        required 
                                    >
                                        <InputLabel>Reference: {entityType.name.toUpperCase()}</InputLabel>
                                        <Select                                  
                                            label={"Reference: "+ entityType.name.toUpperCase()}
                                            name={"config.refs."+ item}
                                            value={formData?.config?.refs[entityType.id] ?? ""}
                                            onChange={handleOnChange}                        
                                            disabled={(                                            
                                                (formState.disabled)
                                            )}
                                        >       
                                            {Object.keys(entityType.fields || {}).map(function (key, i) {
                                                if (
                                                    (entityType.fields[key].type === "ref")                                                
                                                ) {
                                                    return (
                                                        <MenuItem key={i} value={key}>{entityType.fields[key].label} [Hard reference]</MenuItem>
                                                    )
                                                }                                            
                                            })}                                        
                                        </Select>
                                    </FormControl>
                                )
                            }
                        })}
                    </SimpleUICommonReactCase>

                    <SimpleUICommonReactCase value={"json"}>
                        <TextField
                            fullWidth
                            id="config.jsonSchema"
                            label="Schema"
                            multiline
                            rows={20}
                            value={formData?.config?.jsonSchema ?? ""}
                            onChange={handleOnChange}
                            disabled={formState.disabled}                    
                        />
                    </SimpleUICommonReactCase>
                </SimpleUICommonReactSwitch>
                                        
        </React.Fragment>
    )
}