// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import { useNavigate, useBlocker } from "react-router-dom"
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Paper,
    AppBar,
    Stack,
    DialogContentText,    
    Button,
    IconButton,
    Collapse,
    Alert,
    TextField,
    // Grid,
    Typography,
    Box,
    CircularProgress,
    Tab,
    Tabs,
    TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,  
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
import { DataGrid } from '@mui/x-data-grid'
import Grid from '@mui/material/Unstable_Grid2'

import { styled } from '@mui/material/styles';
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Edit as EditIcon,
    Close as CloseIcon,
    Delete as RemoveIcon,
    CarCrash, 
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAppState,
    SimpleUIUseAppDispatch,
    SimpleUIAppUpdateRoutes,    
    SimpleUIAuthState,
    SimpleUIDrawer,
    SimpleUIDialogConfirm
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Dialogg from '../../entities/components/dialog'
import {API_URL_SIMPLECRM, setStateKeyValuePair} from '../../../components/common'
import FormRun from './run'
import FormParameter from './parameter'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

const sxPaper = {p: 2, m: 2, mb: 0}

const DEFAULT_VALUE_TYPE = {
    name: null,
    functionId: null,
    parameters: []
}

export default () => {
    const params = useParams()
    const navigate = useNavigate()    
    
    const appDispatch = SimpleUIUseAppDispatch()
    const { layouts, settings } = SimpleUIAppState()    
    const { user, accessToken, authorized } = SimpleUIAuthState()
    
    // #region FORM
    const formDefaultState = {
        isInitializing: true,

        mainButtonDisabled: true,
        mainButtonLoading: false,

        runButtonLoading: false,        

        disabled: false,
        dirty: false,
        valid: false,

        reload: false,

        error: false,
        errorText: "",
    }

    const [ formState, setFormState ] = useState(formDefaultState)    
    const [ formDefaultData, setFormDefaultData ] = useState()
    const [ formData, setFormData ] = useState()
    const [ formRefresh, setFormRefresh ] = useState(false)

    const [ functions, setFunctions ] = useState([])

    const [ dialogStateConfirm, setDialogStateConfirm ] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })

    const [dialogStateRun, setDialogStateRun] = useState(false)

    useEffect(() => {
        const asyncFunction = async() => {
            setFunctions(await getFunctions())    

            if (params.workerId == "00000000-0000-0000-0000-000000000000") {
                setFormDefaultData(DEFAULT_VALUE_TYPE)
                return
            } 
    
            try {
                let fetchGet = await fetch(`${API_URL_SIMPLECRM.WORKERS}${params.workerId}`, {
                method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           
    
                if (!fetchGet.ok)
                    throw new Error((await fetchGet.json()).error.code)
    
                setFormDefaultData(structuredClone((await fetchGet.json())))
            } catch (error) {
                handleError(error)
            }                    
        }

        asyncFunction()
    }, [])

    useEffect(() => {
        if (!formDefaultData)
            return

        setFormData(structuredClone(formDefaultData))

    }, [formDefaultData])

    useEffect(() => {
        if (!formData)
            return

        let formDirty = true
        let formValid = 0

        // Validate: Changed
        if (JSON.stringify({...formDefaultData}) === JSON.stringify({ ...formData})) {
            formDirty = false
            formValid++
        }

        // Validate: Name
        if (!formData.name) {
            formValid++
        }

         // Validate: Function
        if (!formData.functionId) {
            formValid++
        }
       
        // Update: Parameters
        if (formData.parameters) {
            if (
                (formData.parameters.count != dateGridRowsParameters.length) ||
                (JSON.stringify(structuredClone(formDefaultData.parameters)) != JSON.stringify(structuredClone(formData.parameters)))
            ) {      
                let rows = []                
                for (const paramenter of formData.parameters) {
                    rows.push(paramenter)
                }
                setDataGridRowsParameters(rows)
            }
        }
               
        setFormState({...formState,
            isInitializing: false,
            error: false,    
            errorText: "",           
            disabled: false, 

            runButtonLoading: false,
            renderButtonLoading: false,

            mainButtonLoading: false,
            mainButtonDisabled: !!(formValid),
            
            dirty: formDirty,
            valid: !!(formValid),            
        })        
    }, [formData, formRefresh])

    const handleOnChange = (event) => {
        const id = (event.target.name || event.target.id)
        const value = event.target.value        

        setFormData((prevState) => {return setStateKeyValuePair(prevState, id, value)})       

        return true
    }

    const handleOnClickMainButton = () => {
        if (!formData.id)
            handleCreate()
        else
            handleUpdate() 
    }   

    const handleOnRun = () => {

        handleRun()
        // setDialogStateConfirm({
        //     open: true,
        //     title: "Warning",
        //     children: (
        //         <DialogContentText id="alert-dialog-description">
        //             You are about to RUN this worker, do you want to continue?
        //         </DialogContentText>),
        //     button1Text: "No",
        //     button2Text: "Yes",
        //     onConfirm: async () => {
        //        await handleRun()
        //     },
        //     onDecline: () => {}
        // })
    }

    const handleCreate = async () => {
        setFormState({ ...formState, disabled: true, mainButtonLoading: true })

        try {            
            let fetchPost = await fetch(API_URL_SIMPLECRM.WORKERS, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(formData)
            })

            if (!fetchPost.ok)
                throw new Error((await fetchPost.json()).error.code)
            
            let newWorker = await fetchPost.json()            

            setFormDefaultData(structuredClone(newWorker))            

            navigate('/workers/'+ newWorker.id)     
        } catch (error) {                      
            handleError(error)
        }        
    }

    const handleUpdate = async () => {
        setFormState({ ...formState, disabled: true, mainButtonLoading: true })

        try {
            let fetchPatch = await fetch(`${API_URL_SIMPLECRM.WORKERS}${params.workerId}`, {
                method: 'PATCH', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(formData)
            })  

            if (!fetchPatch.ok)
                throw new Error((await fetchPatch.json()).error.code)
                        
            setFormDefaultData(structuredClone(formData))
        } catch (error) {
            handleError(error)
        }        
    }

    const handlePurge = async () => {
        setFormState({ ...formState, disabled: true, runButtonLoading: true })

        try {
            let fetchGet = await fetch(`${API_URL_SIMPLECRM.TYPES}${params.typeId}/purge`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                
            })  

            if (!fetchGet.ok)
                throw new Error((await fetchGet.json()).error.code)
            
            setFormRefresh(!formRefresh)
        } catch (error) {
            handleError(error)
        }        
    }

    const handleRun = async () => {
        setDialogStateRun({
            open: true,
            title: "",
            children: (<FormRun dialogState={dialogStateRun} setDialogState={setDialogStateRun} workerId={formData.id}></FormRun>)
        })
    }

    const handleRender = async () => {
        setFormState({ ...formState, disabled: true, renderButtonLoading: true })

        try {
            let fetchGet = await fetch(`${API_URL_SIMPLECRM.TYPES}${params.typeId}/render`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                
            })  

            if (!fetchGet.ok)
                throw new Error((await fetchGet.json()).error.code)
            
            setFormRefresh(!formRefresh)
        } catch (error) {
            handleError(error)
        }        
    }
    
    const handleClose = () => {
        navigate('/workers/') 
    }
    
    const handleError = (error) => {
        switch (error.message) {
            case "ER_WORKER_NOT_FOUND":
                setFormState({ ...formState, error: true, errorText: "Worker not found." })
                break

            case "ER_WORKER_MALFORMED_DATA":
                setFormState({ ...formState, error: true, errorText: "Malformed data." })
                break

            default:
                setFormState({ ...formState, error: true, errorText: "Unexpected error occured." })
        }
        console.log (error.message)
    }
    // #endregion

    // #region BLOCKER
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            formState.dirty &&
            (formData.id) &&
            currentLocation.pathname !== nextLocation.pathname
    )

    useEffect(() => {
        if (blocker.state === "blocked")
            handleBlocked()
    }, [blocker])

    const handleBlocked = () => {
        setDialogDelete({
            open: true,
            title: "Warning",
            children: (
                <DialogContentText id="alert-dialog-description">
                    There are unsaved changes, would you like to proceed?
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: async () => {
                 blocker.proceed()
            },
            onDecline: () => {
                blocker.reset()
            }
        })
    }
    // #endregion

    // #region DIALOGS
    const [ dialogStateBlocked, setDialogBlocked ] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })
    const [ dialogStateDelete, setDialogDelete ] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })
    // #endregion
  
    // #region TAB STATES
    const [ tab, setTab ] = React.useState(1)
    const handleOnChangeTab = useCallback((event, newValue) => {
        setTab(newValue)
    }, [])
    // #endregion

    // #region DATAGRIDS
    // const [ selectorOpenAction, setSelectorOpenAction ] = React.useState(false)
    const dataGridGetRowId = (row) => {
        return row.id
    }

    const [ dataGridColumnsFields, setDataGridColumnsFields ] = useState([
        { field: 'label', headerName: 'Label', flex: 0.3 },
        { field: 'type', headerName: 'Type', flex: 0.2 },
        { field: 'required', headerName: 'Required', width: 100,
            renderCell: (params) => {
                const { row } = params
                if (row.required)
                    return "yes"
                else 
                    return "no"
            }
        },
        { field: 'unique', headerName: 'Unique', width: 100,
            renderCell: (params) => {
                const { row } = params
                if (row.unique)
                    return "yes"
                else 
                    return "no"
            }
        },
        { field: 'options', headerName: 'Options', flex: 0.5 },
        { field: 'buttons', headerName: '', sortable: false, align: "right",
            renderCell: (params) => {
                const { row } = params
                const handleEdit = () => {
                    handleEditField(row.id)
                }

                const handleRemove = () => {
                    handleRemoveField(row.id)
                }

                return (
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>                    
                        <IconButton aria-label="edit" size="small" onClick={handleEdit}>
                            <EditIcon fontSize="inherit"/>
                        </IconButton>
                            
                        <IconButton aria-label="delete" size="small" onClick={handleRemove} disabled={row.hasRef}>
                            <RemoveIcon fontSize="inherit"/>
                        </IconButton>
                    </Stack>
                )
            }           
        },
    ])
    const [ dataGridRowsFields, setDataGridRowsFields ] = useState([])
    const [ dataGridSelectionModelFields, setDataGridSelectionModelFields ] = useState([])

    const handleOnRowSelectionModelChangeFields = (selection) => {
        setDataGridSelectionModelFields(selection)
    }

    // #endregion
    // #region DATAGRID: PARAMS
    const [ dataGridColumnsParameters, setDataGridColumnsParameters ] = useState([
        { field: 'name', headerName: 'Name', flex: 0.3 },
        { field: 'type', headerName: 'Type', flex: 0.2 },
        { field: 'source', headerName: 'Source', width: 100,
            renderCell: (params) => {
                const { row } = params
                if (row?.source) {
                    if (row?.source?.type)
                        return row?.source?.type
                } else { 
                    return ""
                }
            }
        },
        { field: 'buttons', headerName: '', flex: 1.0, sortable: false, align: "right",
            renderCell: (params) => {
                const { row } = params
                const handleEdit = () => {
                    handleParametersEdit(row.id)
                }

                const handleRemove = () => {                    
                    handleParametersDelete(row.id)
                }

                return (
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>                    
                        <IconButton aria-label="edit" size="small" onClick={handleEdit}>
                            <EditIcon fontSize="inherit"/>
                        </IconButton>
                            
                        <IconButton aria-label="delete" size="small" onClick={handleRemove} disabled={row.hasRef}>
                            <RemoveIcon fontSize="inherit"/>
                        </IconButton>
                    </Stack>
                )
            }           
        },
    ])
    const [ dateGridRowsParameters, setDataGridRowsParameters ] = useState([])
    const [ dataGridSelectionModelParameters, setDataGridSelectionModelParameters ] = useState([])
    const handleOnRowSelectionModelChangeParameters = (selection) => {
        setDataGridSelectionModelParameters(selection)
    }
    // #endregion

    // #region FIELD ADD/EDIT
    const [ drawerFieldAddState, setDrawerFieldAddState ] = useState({ open: false })
    const [ drawerFieldEditState, setDrawerFieldEditState ] = useState({ open: false })

    const handleAddField = async () => {
        setDrawerFieldAddState({...drawerFieldAddState, open: true})
    }

    const handleEditField = async () => {
        setDrawerFieldEditState({...drawerFieldEditState, open: true})
    }

    const handleRemoveField = async (id) => {
        setDialogDelete({
            open: true,
            title: "Delete field",
            children: (
                <DialogContentText id="alert-dialog-description">
                    Deleting a field will result in removal of data from entitys created with this type. Do you want to continue?
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: async () => {
                setFormData(prevState => {
                    let newState = structuredClone(prevState)
                    delete newState.fields[id]
                    return newState
                })        
            },
            onDecline: () => {}
        })
    }

    const handleCallbackEditField = (callbackResult) => {
        const { id, ...newField } = callbackResult               

        if (id) {
            setFormData(prevState => {
                let newState = structuredClone(prevState)
                newState.fields[id] = newField
                return newState
            })
        } else {
            setFormData(prevState => {
                let newState = structuredClone(prevState)
                if (!newState.fields)
                    newState.fields = {}
                newState.fields[crypto.randomUUID()] = newField
                return newState
            })
        }
    }
    // #endregion    

    // #region PARAM ADD/EDIT
    const [ drawerStateParametersAdd, setDrawerStateParametersAdd ] = useState({ open: false })
    const [ drawerStateParametersEdit, setDrawerStatParametersEdit ] = useState({ open: false })

    const handleParametersAdd = async () => {
        setDrawerStateParametersAdd({...setDrawerStateParametersAdd, open: true})
    }

    const handleParametersEdit = async () => {
        setDrawerStatParametersEdit({...setDrawerStatParametersEdit, open: true})
    }

    const handleParametersDelete = async (id) => {        
        setDialogStateConfirm({
            open: true,
            title: "Delete parameter",
            children: (
                <DialogContentText id="alert-dialog-description">
                   You are about to delete a parameter. Do you want to continue?
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: async () => {
                setFormData(prevState => {
                    let newState = structuredClone(prevState)
                    newState.parameters = newState.parameters.filter(paremeter => paremeter.id !== id)
                    return newState
                })        
            },
            onDecline: () => {}
        })
    }

    const handleCallbackParemetersEdit = (callbackResult) => {
        const newParameter = callbackResult               
        // console.log (newParameter)

        if (newParameter.id) {                
            setFormData(prevState => {
                let newState = structuredClone(prevState)
                newState.parameters[newState.parameters.map(function(item) { return item.id }).indexOf(newParameter.id)] = newParameter
                return newState
            })
        } else {
            setFormData(prevState => {
                let newState = structuredClone(prevState)
                // if (!newState.parameters)
                //     newState.parameters = []

                newParameter.id = crypto.randomUUID()
                newState.parameters.push(newParameter) 
                return newState
            })
        }
    }
    // #endregion   
            
        const getEntityTypes = async () => {
            let output = []    
            try {
                let getTypes = await fetch(API_URL_SIMPLECRM.TYPES, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           
    
                if (!getTypes.ok)
                    throw new Error((await getTypes.json()).error.code)
    
                output = await getTypes.json()
            } catch (error) {            
                handleError(error)
            }  
    
            return output
        }

        const getFunctions = async () => {
            let output = []    
            try {
                let fetchGet = await fetch(API_URL_SIMPLECRM.FUNCTIONS, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           
    
                if (!fetchGet.ok)
                    throw new Error((await fetchGet.json()).error.code)
    
                output = await fetchGet.json()
            } catch (error) {            
                handleError(error)
            }  
    
            return output
        }

    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    } 
    
    return (
        <React.Fragment>
            <SimpleUIDialogConfirm dialogState={dialogStateConfirm} setDialogState={setDialogStateConfirm}/>           

            <Dialogg fullWidth={true} maxWidth={'md'} dialogState={dialogStateRun} setDialogState={setDialogStateRun}></Dialogg>

            <SimpleUIDrawer state={drawerStateParametersAdd} setState={setDrawerStateParametersAdd} callback={handleCallbackParemetersEdit}>
                <FormParameter/>
            </SimpleUIDrawer>
            <SimpleUIDrawer state={drawerStateParametersEdit} setState={setDrawerStatParametersEdit} parameterId={dataGridSelectionModelParameters} worker={formData} callback={handleCallbackParemetersEdit}>
                <FormParameter/>
            </SimpleUIDrawer>            
            
            <Paper sx={{p: 1 , top: "0px", position:"sticky", zIndex: 10}} >
                <Stack spacing={2} direction="row" justifyContent="end">
                        <LoadingButton variant="contained" color="error" loading={formState.runButtonLoading} disabled={formState.disabled || !formData.id} onClick={handleOnRun}>
                            Run
                        </LoadingButton>                        

                        <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={handleOnClickMainButton}>
                            {(!formData.id) ? "Create" : "Save" }
                        </LoadingButton>  
                        
                        <Button variant="outlined"  aria-label="close" onClick={handleClose} disabled={formState.disabled}>
                            Close
                        </Button>
                </Stack>

                <Collapse in={formState.error}>
                    <Alert variant="filled" severity="error" sx={{mb: 2}}
                        action={
                            <IconButton aria-label="close" color="inherit" size="small"
                                onClick={() => {
                                    setFormState({ ...formState, error: false })
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {formState.errorText}
                    </Alert>
                </Collapse>
            </Paper>
            
            <Grid container>
                <Grid item md={12}>
                    <Paper sx={sxPaper}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography variant='h6'>Details</Typography>
                            </Grid>
                            <Grid xs={12}>
                                {formData.id && (
                                    <TextField
                                        margin="dense"                    
                                        label="Id"
                                        fullWidth                    
                                        value={formData.id ?? ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}                                                       
                                    />
                                )}
                                <TextField
                                    id={"name"}

                                    label={"Name"}

                                    value={formData.name}
                                    onChange={handleOnChange}
                                
                                    required={true}
                                    
                                    variant="outlined"
                                    margin="dense"                        
                                    fullWidth={true}
                                    autoComplete="off"
                                    
                                    inputProps={{ maxLength: 50}}
                                    
                                    disabled={(formState.locked || formState.disabled)}
                                />                                
                            </Grid>
                        </Grid>
                    </Paper>                        
                    
                </Grid>

                <Grid xs={12}>
                    <Paper sx={sxPaper}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography variant='h6'>Function</Typography>
                            </Grid>
                            <Grid xs={12}>
                                {/* Function */}
                                <FormControl sx={{mt: 1, mb: 1}}
                                    fullWidth 
                                    required 
                                >
                                    <InputLabel>Function</InputLabel>
                                    <Select                                  
                                        label="Function"
                                        name="functionId"                                        
                                        value={formData.functionId ?? ""}
                                        onChange={handleOnChange}                        
                                        disabled={(                                            
                                            (formState.disabled)
                                        )}
                                    >     
                                        {functions.map(function (element, i)  {
                                            return (
                                                <MenuItem key={i} value={element.id}>{element.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid xs={12}>
                    <Paper sx={sxPaper}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography variant='h6'>Parameters</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <DataGrid
                                    autoHeight
                                    hideFooter
                                    columns={dataGridColumnsParameters} 
                                    rows={dateGridRowsParameters}
                                    onRowSelectionModelChange={handleOnRowSelectionModelChangeParameters}
                                    rowsPerPageOptions={[100]} 
                                    selectionModel={dataGridSelectionModelParameters}
                                    getRowId={dataGridGetRowId}                        
                                />
                                <Button onClick={handleParametersAdd}>Add parameter</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>
                
        </React.Fragment>
    )
}

