// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Collapse,    
    Box,
    Stack,
    Paper,
    Typography,
    Alert,
    Button,    
    IconButton,    
    LinearProgress,
    Fade
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,    
    SimpleUICommonReactInitializing,
    SimpleUICommonUseInterval,
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {API_URL_SIMPLECRM} from '../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Render from '../../entities/components/render'
import dialog from '../../entities/components/dialog'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const Buttons = (props) => {
    return (
        <React.Fragment>            
            <LoadingButton color="inherit" loading={props.formState.mainButtonLoading} disabled={props.formState.mainButtonDisabled || props.formState.locked} onClick={props.onRun}>
                Send
            </LoadingButton>          
            <Button color="inherit" disabled={props.formState.disabled} onClick={props.onClose}>
                Close
            </Button>
        </React.Fragment>
    )
}
// ----------------------------------------------------------------------
export default (props) => {
    const { workerId, dialogState, setDialogState, setDialogTitle, setDialogButtons } = props
    const { accessToken } = SimpleUIAuthState()

    // #region FORM
    const formDefaultState = {
        isInitializing: true,        
        disabled: false,        
        mainButtonDisabled: false,
        mainButtonLoading: false,        
        valid: false,
        dirty: false,
        running: false,
        completed: false,
        error: false,
        errorText: "",
        runError: false,    
        runErrorText: ""
    }
    const formValueDefault = null

    const [ formState, setFormState ] = useState(formDefaultState)       
    const [ formData, setFormData ] = useState(formValueDefault)
    const [ formDefaultData, setFormDefaultData ] = useState(formValueDefault)    

    const [ parameters, setParameters ] = useState(null)
    const [ process, setProcess ] = useState(null)

    useEffect(() => {
        const asyncFunction = async () => {
            try {
                let fetchGet = await fetch(`${API_URL_SIMPLECRM.WORKERS}${workerId}`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           
    
                if (!fetchGet.ok)
                    throw new Error((await fetchGet.json()).error.code)
    
                setFormData(structuredClone(await fetchGet.json()))
            } catch (error) {            
                handleError(error)
            }  
        }

        asyncFunction()
    }, [dialogState.open])

    useEffect(() => {
        if (formData === null)
            return

        let valid = 0

        const input = {}
        for (const paramenter of formData.parameters) {
            input[paramenter.id] = {
                valid: false,
                value: null,
                callback: undefined
            }
        }
        setParameters(input)

        setDialogTitle(formData.name)

        setFormState({...formState,
            isInitializing: false,
            mainButtonLoading: false,
            // mainButtonDisabled: !!(valid),
            error: false,
            errorText: ""  
        })
        
    }, [formData])

    useEffect(() => {
        if (formData === null)
            return

        const valid = ((formData.parameters.find(parameter => (parameter.type == "entity" && !parameters[parameter.id].valid))) ? false : true)
        
        setFormState({...formState,
            valid: valid,
            mainButtonDisabled: !valid,
        })

    },[parameters])

    useEffect(() => {
        if (!process)
            return 

        if (process.error != null) {
            setProcess(null)
            setFormState({...formState,
                disabled: false,
                mainButtonLoading: false,
                running: false,
                completed: false,
                runError: true,
                runErrorText: process.error
            })
            return
        }


        // if (process.progress == 100) {
        if (process.status != 1) {
            setProcess(null)
            setFormState({...formState,
                disabled: false,
                mainButtonLoading: false,
                running: false,
                completed: true,
            })
        }
            
    }, [process])

    SimpleUICommonUseInterval(async () => {
        try {
            let fetchGet = await fetch(`${API_URL_SIMPLECRM.WORKERS}process/${process.id}`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!fetchGet.ok)
                throw new Error((await fetchGet.json()).error.code)

            setProcess(await fetchGet.json())
        } catch (error) {            
            handleError(error)
        }
    }, process ? 2500 : null)

    // const handleOnChange = (event) => {
    //     const id = (event.target.id || event.target.name)        
    //     const value = event.target.value

    //     // console.log (id +" "+ value)
        
    //     setFormData(prevState => {
    //         const newState = structuredClone(prevState)
    //         const path = id.split('.')
    //         const last = path.pop()            
    //         path.reduce((ac,a) => ac[a], newState)[last] = value

    //         // SOURCE
    //         if (
    //             (newState.type != prevState.type) ||
    //             (!newState.source)
    //         )
    //             newState.source = {}

    //         // SOURCE.TYPE
    //         if (newState?.source?.type != prevState?.source?.type)
    //             newState.source = {type: newState.source.type}
          
    //         return newState
    //     })

    //     return true
    // }

    const handleOnClickMainButton = () => {
        // callback({...formData})
        // handleClose()        
    }

    const handleOnChangeRender = (parameterId, renderFormState, typeId, entityData, callback) => {
        setParameters({...parameters, [parameterId]: {valid: renderFormState.valid, value: "", callback: callback}})
    }    

    const handleOnClose = () => {
        handleClose()
    }

    const handleOnRun = async () => {
        setFormState({...formState,
            disabled: true,
            mainButtonLoading: true,
            running: true,
        })
        
        const payload = {
            input: {}
        }

        for (const parameter of formData.parameters) {
            if (parameter.type == "entity")
                payload.input[parameter.id] = (await parameters[parameter.id].callback()).id
            else
                payload.input[parameter.id] = parameter.source.value
        }

        let fetchPost = await fetch(`${API_URL_SIMPLECRM.WORKERS}${formData.id}/run`, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
            body: JSON.stringify(payload)
        })

        if (!fetchPost.ok)
            throw new Error(await fetchPost.text())
                
        setProcess(await fetchPost.json() )        
    }

    const handleOnDone = async () => {

    }

    const handleClose = () => {
        // setFormState(formDefaultState)
        // setFormData(formValueDefault)        
        setDialogState({...dialogState, open: false})
    }

    const handleError = (error) => {
        setFormState({ ...formState, isInitializing: false })                     

        switch (error.message) {
            default: {
                setFormState({ ...formState, createLoading: false, error: true, errorText: "Der opstod en uventet fejl." })
            }
        }
    }
    // #endregion

    setDialogButtons((<Buttons formState={formState} onClickMainButton={handleOnClickMainButton} onClose={handleOnClose} onRun={handleOnRun} process={process} ></Buttons>))

    // if (formState.isInitializing)
    //     return (<SimpleUICommonReactInitializing></SimpleUICommonReactInitializing>)

    
    return (
        <React.Fragment> 
            <Collapse in={formState.completed}>
                <Alert variant="filled" severity="success">
                    Task completed without errors.
                </Alert>
            </Collapse>
            
            <Collapse in={formState.runError}>
                <Alert variant="filled" severity="error">
                    {formState.runErrorText}
                </Alert>
            </Collapse>  


            <Collapse in={formState.error}>
                <Alert variant="filled" severity="error" 
                    action={
                        <IconButton aria-label="close" color="inherit" size="small"
                            onClick={() => {
                                setFormState({ ...formState, error: false })
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {formState.errorText}
                </Alert>
            </Collapse>  

            <Collapse in={formState.running}>
            <Paper elevation={3} sx={{ p: 2}}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress variant="determinate" value={process?.progress} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <Typography
                                variant="body2"
                                // sx={{ color: 'white' }}
                            >{`${Math.round(process?.progress ?? 0)}%`}</Typography>
                        </Box>
                    </Box>
                    </Paper>
            </Collapse>

            {/* <Collapse in={formState.completed}>

            </Collapse> */}
            
            <Collapse in={(!formState.running && !formState.completed && !formState.runError)}>
            <Paper elevation={3} sx={{ p: 2}}>            
                {/* OPTIONS */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >                 
                    
                    {/* <FormGroup row={true}>
                        <FormControlLabel control={
                            <Checkbox                                            
                                checked={!!formData.default ?? false}
                                disabled={
                                    (formState.disabled)                                                                        
                                }
                                onChange={(event) => {handleOnChange({target: {name: "default", value: event.target.checked}})} }
                            />
                        } label="Default" />                        
                    </FormGroup>   */}
                </Stack>            
                {(formData?.parameters || []).map(function(data) {
                    if (data.type == "entity") {
                        return (
                            <Render typeId={data.source.entityType} onChange={(renderFormState, typeId, entityData, callback) => {handleOnChangeRender(data.id, renderFormState, typeId, entityData, callback)}}></Render>
                        )
                    }
                })}                
            </Paper>                                           
            </Collapse>
        </React.Fragment>
    )
}