// --------------------------------------------------------- REACT ------
// import * as React from 'react'
// import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// import {} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {    
//     SimpleUIAuthState,    
// } from './../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// import emailValidator from 'email-validator'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------

// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

import {API_URL_SIMPLECRM, UserGroups} from './common'


// import EmployeeIcon from '@mui/icons-material/Badge'
// import CustomersIcon from '@mui/icons-material/Contacts'

import Entities from "../pages/entities"
import EntitiesEdit from "../pages/entities/edit"


const defaultExport = async (user, accessToken) => {
    // console.log ("Fetching navigation")
    // console.log (accessToken)

    if (accessToken === "")
        return {}
    
    try {            
        const result = []
        let postType = await fetch(API_URL_SIMPLECRM.TYPES, {
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },            
        })

        if (!postType.ok)
            throw new Error((await postType.json()).error.code)
        
        for (const type of (await postType.json())) {            
            if (type?.navigation?.url) {
                
                result.push ({
                    path: `${type.navigation.url}/:entityId`,
                    protected: {
                        element: EntitiesEdit,                        
                        props: {
                            key: `entity-edit-${type.id}`,
                            typeId: type.id
                        },
                        restrictions: {
                            usergroups: [
                               UserGroups.SIMPLEDATA_USERS.id
                            ]
                        }
                    }
                })   

                result.push ({
                    path: `${type.navigation.url}/`,
                    protected: {
                        key: `list-${type.id}`,                        
                        element: Entities,                        
                        props: {
                            key: `entity-edit-${type.id}`,
                            typeId: type.id
                        },
                        navigation: {
                            icon: type.navigation.icon,
                            
                            // icon: EmployeeIcon,
                            label: `${type.navigation.label}`,
                            sort: type.navigation.sort,
                        },
                        restrictions: {
                            usergroups: [
                               UserGroups.SIMPLEDATA_USERS.id
                            ]
                        }
                    }
                })
            }
        }    

        return result
    } catch (error) {                      
        console.log (error)
    }        
}

export default defaultExport