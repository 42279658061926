export const initialState = {    
    appInitializing: true,    
    routes: [],
    routeBuilder: [],
    router: undefined
}

export const AppReducer = (initialState, action) => {

  switch (action.type) {
    case "INITIALIZED":
        return {
            ...initialState,           

            appInitializing: false,            
            settings: action.payload.settings,
            layouts: action.payload.layouts
        }

    case "UPDATED_ROUTES":
        return {
            ...initialState,            
            router: action.payload.router
        }

    default:
       throw new Error(`Unhandled action type: ${action.type}`);
  }
}