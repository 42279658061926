// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Collapse,    
    Box,
    Stack,
    Paper,
    Typography,
    Alert,
    Button,    
    TextField,            
    IconButton,    
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Tabs,
    Tab,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
    Edit as EditIcon,
    Delete as DeleteIcon,
    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,    
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {API_URL_SIMPLECRM, setStateKeyValuePair} from '../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

const DEFAULT_VALUE_USERTYPE = {
    name: "",
    permissionGroup: "",
    dashboard: "",
    selfEnabled: true,
    selfUrl: "",
    selfLabel: ""
}

export default (props) => {
    const {drawerState, setDrawerState, userTypeId, callback} = props

    const {user, accessToken} = SimpleUIAuthState()
    
    // #region FORM
    const formDefaultState = {
        isInitializing: true,        
        disabled: false,                              
        mainButtonDisabled: true,
        mainButtonLoading: false,        
        error: false,    
        errorText: ""        
    }
    const [formState, setFormState] = useState(formDefaultState)       
    
    const [formDefaultData, setFormDefaultData] = useState()
    const [formData, setFormData] = useState()

    const [dashboards, setDashboards] = useState([])
    const [permissionGroups, setPermissionGroups] = useState([])
   
    useEffect(async () => {
        if (!drawerState.open)
            return
        
        if (userTypeId) {            
            try {
                let fetchGet = await fetch(`${API_URL_SIMPLECRM.USERTYPES}${userTypeId}`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           

                if (!fetchGet.ok)
                    throw new Error((await fetchGet.json()).error.code)

                setFormDefaultData(await fetchGet.json())
            } catch (error) {
                handleError(error)
            }
        } else {            
            setFormDefaultData(DEFAULT_VALUE_USERTYPE)
        }

        setDashboards(await getDashboards())
        setPermissionGroups(await getPermissionGroups())
    }, [drawerState.open])

    useEffect(async () => {
        setFormData(formDefaultData)        
    }, [formDefaultData])

    useEffect(() => {
        if (!formData)
            return

        let valid = 0

        // Validate: CHANGED
        if (JSON.stringify(structuredClone(formDefaultData)) === JSON.stringify(structuredClone(formData))) {
            valid++
        }

        // Validate: NAME
        if ((formData?.name || "").length == 0) {
            valid++
        }

        // Validate: PERMISSIONGROUP
        if ((formData?.permissionGroup || "").length == 0) {
            valid++
        }

        // Validate: DASHBOARD
        if ((formData?.dashboard || "").length == 0) {
            valid++
        }
                
        // // Validate: TYPE
        // if ((formData.type || "") == "") {
        //     valid++
        // }

        // // Validate: TEXTFIELD
        // if (formData.type == "textfield") {
        //     if ((formData.subType || "") == "")
        //         valid++
        // }

        // // Validate: UPLOAD
        // if (formData.type == "upload") {
        //     if ((formData.subType || "") == "")
        //         valid++
        // }

        // // VALIDATE: USER
        // if (formData.type == "user") {
        //     if (formData?.dataSource) {
        //         if ((formData.dataSource?.name || "") == "")
        //             valid++

        //         if ((formData.dataSource?.email || "") == "")
        //             valid++
        //     } 

        //     if ((formData.usertype || "") == "")
        //         valid++
        // }

        setFormState({...formState,
            isInitializing: false,
            mainButtonLoading: false,
            mainButtonDisabled: !!(valid),
            error: false,
            errorText: ""  
        })
        
    }, [formData])

    const handleOnClickMainButton = () => {
        if (formData.id)
            handleUpdate()
        else 
            handleCreate()
    }

    const handleOnChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value
        
        setFormData((prevState) => {return setStateKeyValuePair(prevState, id, value)})

        return true
    }

    const handleCreate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {            
            let fetchPost = await fetch(`${API_URL_SIMPLECRM.USERTYPES}`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(formData)
            })

            if (!fetchPost.ok)
                throw new Error((await fetchPost.json()).error.code)
            
            setFormDefaultData(structuredClone(await fetchPost.json()))

            if (callback)
                callback()

        } catch (error) {                      
            handleError(error)
        }        
    }

    const handleUpdate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {
            let fetchPatch = await fetch(`${API_URL_SIMPLECRM.USERTYPES}${formData.id}`, {
                method: 'PATCH', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(formData)
            })  

            if (!fetchPatch.ok)
                throw new Error((await fetchPatch.json()).error.code)
            
            setFormDefaultData(structuredClone(formData))

            if (callback)
                callback()

        } catch (error) {
            handleError(error)
        }                    
    }

    const handleClose = () => {
        setFormState(formDefaultState)
        setFormData(DEFAULT_VALUE_USERTYPE)        
        setDrawerState({...drawerState, open: false})
    }

    const handleError = (error) => {
        setFormState({ ...formState, isInitializing: false })                     

        switch (error.message) {
            default: {
                setFormState({ ...formState, createLoading: false, error: true, errorText: "Der opstod en uventet fejl." })
            }
        }
    }
    // #endregion

    // #region FUNCTIONS
    const getDashboards = async () => {
        let output = []    
        try {
            let getTypes = await fetch(API_URL_SIMPLECRM.DASHBOARDS, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getTypes.ok)
                throw new Error((await getTypes.json()).error.code)

            output = await getTypes.json()
        } catch (error) {            
            handleError(error)
        }  

        return output
    }

    const getPermissionGroups = async () => {
        let output = []    
        try {
            let getTypes = await fetch(API_URL_SIMPLECRM.PERMISSIONGROUPS, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getTypes.ok)
                throw new Error((await getTypes.json()).error.code)

            output = await getTypes.json()
        } catch (error) {            
            handleError(error)
        }  

        return output
    }
    // #endregion

    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    }     
    
    return (
        <React.Fragment>            
            <Collapse in={formState.error}>
                <Alert variant="filled" severity="error" 
                    action={
                        <IconButton aria-label="close" color="inherit" size="small"
                            onClick={() => {
                                setFormState({ ...formState, error: false })
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {formState.errorText}
                </Alert>
            </Collapse>  
            
            <Paper elevation={3} sx={{ p: 2}}>
            
                {/* OPTIONS */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="h6" sx={{mb: 1}}>
                        {formData.id ? "Edit UserType" : "Create UserType" }
                    </Typography>
                                    
                </Stack>                

                {/* LABEL */}
                <TextField sx={{mb: 1}}
                    required                                     
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth                                        
                    autoComplete="off"
                    value={formData.name ?? ""}
                    disabled={formState.disabled}
                    onChange={handleOnChange}                    
                />

                {/* PERMISSIONGROUP */}
                <FormControl sx={{mt: 1, mb: 1}}
                    fullWidth 
                    required
                >   
                    <InputLabel>PermissionGroup</InputLabel>
                    <Select
                        label="PermissionGroup"
                        name="permissionGroup"
                        value={formData.permissionGroup ?? ""}
                        onChange={handleOnChange}
                        disabled={(
                            (formState.disabled)                            
                        )}
                    >                            
                        {permissionGroups.map(function (permissionGroup, i)  {
                            return (
                                <MenuItem key={i} value={permissionGroup.id}>{permissionGroup.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                {/* DASHBOARD */}
                <FormControl sx={{mt: 1, mb: 1}}
                    fullWidth 
                    required
                >   
                    <InputLabel>Dashboard</InputLabel>
                    <Select
                        label="Dashboard"
                        name="dashboard"
                        value={formData.dashboard ?? ""}
                        onChange={handleOnChange}
                        disabled={(
                            (formState.disabled)                            
                        )}
                    >                            
                        {dashboards.map(function (dashboard, i)  {
                            return (
                                <MenuItem key={i} value={dashboard.id}>{dashboard.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Paper>
            <Paper elevation={3} sx={{ p: 2}}>
                        
            {/* SELFURL */}
            <TextField sx={{mb: 1}}
                required                                     
                margin="dense"
                id="selfUrl"
                label="URL"
                fullWidth                                        
                autoComplete="off"
                value={formData.selfUrl ?? ""}
                disabled={formState.disabled}
                onChange={handleOnChange}                    
            />

            {/* SELFLABLE */}
            <TextField sx={{mb: 1}}
                required                                     
                margin="dense"
                id="selfLabel"
                label="Label"
                fullWidth                                        
                autoComplete="off"
                value={formData.selfLabel ?? ""}
                disabled={formState.disabled}
                onChange={handleOnChange}                    
            />
        </Paper>
                                
            <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                    <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={handleOnClickMainButton}>
                        {formData.id ? "Save" : "Create" }
                    </LoadingButton>       
                </Stack>                   
            </Box>                
        </React.Fragment>
    )
}