import React from "react"
import { createBrowserRouter, Navigate} from "react-router-dom"

const DEBUG = true

export async function initialize(dispatch, payload) {
    if (DEBUG)
        console.debug ("APP: Initializing") 
    
    dispatch({ type: 'INITIALIZED', payload: payload })
}

export async function updateRoutes(dispatch, payload) {
    if (DEBUG)
        console.debug ("APP: Updating routes") 

    const {user, accessToken, authorized, layouts, settings} = payload

    let routes = []
    for (const element of settings.routes) {
        if (typeof element === 'function') { 
            routes = routes.concat((await element(user, accessToken)))
        } else  {
            routes.push(element)
        }
    }

    const navigation =  []
    const publicRoutes = []
    const protectedRoutes = []

    for (const route of routes) {
        if ((route.protected != undefined) && authorized) {
            if (route.protected?.restrictions) {
                let approved = false

                for (const usergroupId of route.protected.restrictions.usergroups) {                                                
                    if (user.usergroups.find(o => o.id === usergroupId)) {
                        approved = true
                        break
                    }
                }
                    
                if (!approved)    
                    continue
            }

            protectedRoutes.push ({
                path: route.path,                    
                element: <route.protected.element settings={settings} {...route.protected.props}/>    
            })

            if (route.protected.navigation) {
                navigation.push({                        
                    path: route.path,
                    navigation: route.protected.navigation                        
                })
            }
        } else if (route.public) {
            publicRoutes.push ({
                path: route.path,
                element: <route.public.element settings={settings} {...route.public.props}/>    
            })                

            if (route.public.navigation) {
                navigation.push({
                    path: route.path,
                    navigation: route.public.navigation
                })
            }
        }               
    }

    publicRoutes.push (
        {
            path: "*",
            element: <Navigate to="/" replace />
        }
    )

    protectedRoutes.push (
        {
            path: "*",
            element: <Navigate to="/" replace />
        }
    )

    const router = createBrowserRouter([
        {
            element: <layouts.public settings={settings} navigation={navigation}></layouts.public>,
            children: [...publicRoutes]
        },
        {
            element: <layouts.protected settings={settings} navigation={navigation}></layouts.protected>,
            children: [...protectedRoutes]
        },
        
    ])
    
    dispatch({ type: 'UPDATED_ROUTES', payload: {routes: routes, router: router} })
}
 