// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    TextField,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

export default (props) => {    
    return (
        <TextField
            id={props.fieldId}    

            label={props.type.fields[props.fieldId].label}

            value={props.data[props.fieldId]}
            onChange={props.onChange}
        
            required={props.type.fields[props.fieldId].required}
            
            variant="outlined"
            margin="dense"                        
            fullWidth={true}
            
            inputProps={{ maxLength: props.type.fields[props.fieldId].maxLength || 250}}
            
            disabled={(props.formState.locked || props.formState.disabled)}
            
            autoComplete="off"       
            
            error={(props.formState.fieldError[props.fieldId] || {}).error}

            helperText={(props.formState.fieldError[props.fieldId] || {}).text}
        />
    )
}