// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    CircularProgress,
    Fade,
    Fab,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Delete as RemoveIcon,     
    CloudDownload as DownloadIcon,
    CloudUpload as UploadIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

const Image = (props) => {
    const [preview, setPreview] = useState()    
    const [hover, setHover] = useState(false)

    useEffect(() => {
        props.onGetPreview(props.storageId)
        .then (result => {
            setPreview(result)
        }).catch(error => {
            props.onError (error)
        })
    }, [])

    const handleMouseEnter = () => {
        if (!(props.formState.locked || props.formState.disabled))
            setHover(true)
    }

    const handleMouseLeave = () => {
        if (!(props.formState.locked || props.formState.disabled))
            setHover(false)
    }    

    return (
        <React.Fragment>
            <Box 
                display="flex" 
                position="relative" 
                justifyContent="center" 
                
                onMouseOver={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                        
                sx={{
                    mt: 1,
                    mr: 1,                
                    alignItems: 'center', 
                    height: '100px', 
                    width: '100px', 
                    minHeight: '100px', 
                    minWidth: '100px', 
                    border: 1, 
                    borderRadius: 2, 
                    borderColor: '#bbbbbb',
                    overflow: 'hidden'
                }}
            >                
                <Fade in={(props.formState.locked || props.formState.disabled || (props.uploadProgress > 0 && props.uploadProgress < 100))}>
                    <Box position="absolute" right="0px"
                        sx={{
                            zIndex: 1000,                
                            backgroundSize: "cover",
                            backgroundColor: "rgba(255,255,255,0.3)",
                            backgroundRepeat: "no-repeat",                                
                            height: "100%",
                            width: "100%",                                    
                        }}                                    
                    />
                </Fade>

                <Fade in={preview}>
                    <Box 
                        sx={{                                
                            backgroundSize: "cover",
                            backgroundImage: `url("${preview || ""}")`,                                
                            backgroundRepeat: "no-repeat",                                
                            height: "100%",
                            width: "100%",                                    
                        }}                                    
                    />
                </Fade>

                <Fade in={hover}>
                    <Box
                        display="flex"                         
                        sx={{                        
                            alignItems: 'center', 
                        }}
                    >
                        <Box m={1} position="absolute" left="0px">                                                        
                            <Fab 
                                size="small" 
                                color="primary" 
                                onClick={() => {props.onDownloadFile(props.storageId)}}

                                disabled={((Array.isArray(props.meta)) ? !(props.meta.find((e) => e.filename == props.storageId)) : false) }
                            >
                                <DownloadIcon fontSize='small'/>
                            </Fab>
                        </Box>
                        <Box m={1} position="absolute" right="0px">                                                        
                            <Fab 
                                size="small" 
                                color="error" 
                                onClick={() => {props.onRemoveFile(props.storageId)}}
                            >
                                <RemoveIcon fontSize='small'/>
                            </Fab>
                        </Box>
                    </Box>
                </Fade>                
            </Box>
        </React.Fragment>
    )
}

export default (props) => {
    return (
        <React.Fragment>            
            <Box
                sx={{   
                   
                    display: 'flex',
                    flexWrap: 'wrap',                    
                    pl: 1,
                    pb: 1,
                    width: '100%',                    
                    border: 1, 
                    borderRadius: 2, 
                    borderColor: '#bbbbbb'                    
                }}
            >                
                {props.data[props.fieldId].map(function(storageId) {
                    return (
                        <Image
                            {...props}

                            key={storageId}                         
                            storageId={storageId}
                            // onGetPreview={props.onGetPreview}
                            // onDownloadImage={props.onDownloadFile}
                            // onRemoveImage={props.onRemoveFile}
                            // uploadProgress={props.uploadProgress}                            
                            // meta={props.meta}
                        />
                    )
                })}
                <Box
                    display="flex" 
                    position="relative" 
                    justifyContent="center" 

                    sx={{
                        mt: 1,
                        mr: 1,
                        alignItems: 'center', 
                        height: '100px', 
                        width: '100px', 
                        minHeight: '100px', 
                        minWidth: '100px', 
                        border: 1, 
                        borderRadius: 2, 
                        borderColor: '#bbbbbb',
                        overflow: 'hidden'
                    }}
                >
                        
                    <Fade in={(props.uploadProgress == 0)}>
                        <Box m={1} position="absolute">
                            <label htmlFor={props.fieldId +"-file"}>
                                <Fab
                                    component="span" 
                                    size="small" 
                                    color="success"                                     
                                    disabled={(props.formState.locked || props.formState.disabled)}
                                >
                                    <UploadIcon fontSize='small'/>
                                </Fab>
                            </label>
                        </Box>
                    </Fade>

                    <Fade in={(props.uploadProgress > 0)}>
                        <Box position="absolute">
                            <CircularProgress  />
                        </Box>
                    </Fade>                                                
                </Box>
            </Box>    
                                        
        </React.Fragment>
    )   
}