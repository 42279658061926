// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Typography
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Refresh as RefreshIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    Close as CloseIcon,
    Save as SaveIcon,
    Visibility as ShowIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default (props) => {   
    const {dialogState, setDialogState } = props
   
    const [ buttons, setButtons ] = useState(null)
    const [ children, setChildren ] = useState(null)
    const [ title, setTitle ] = useState(null)

    useEffect(() => {
        if (dialogState.children)
            setChildren(React.cloneElement(dialogState.children, {dialogTitle: title, setDialogTitle: setTitle, dialogButtons: buttons, setDialogButtons: setButtons}))
    },[dialogState])    

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return   
    }
    
    return (
        <Dialog 
            // fullWidth={props.fullWidth} 
            // fullScreen
            // TransitionComponent={Transition}
            maxWidth={props.maxWidth}
            fullWidth

            open={dialogState.open}

            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>            
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {title}
                    </Typography>            
                    {buttons}                
                </Toolbar>                
            </AppBar>
            {children}
        </Dialog>
    )
}
