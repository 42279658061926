// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI LAB ----
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Page from '../../components/gate/page'
import FormActivation from '../../components/gate/formActivation'
// ----------------------------------------------------------------------

export default (props) => {
    const { settings } = props
    return (    
        <Page>
            <FormActivation settings={settings}></FormActivation>
        </Page>  
    )
}