// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI LAB ----
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Page from '../../components/gate/page'
import FormActivationReset from '../../components/gate/formActivationReset'
// ----------------------------------------------------------------------

export default (props) => {
    return (    
        <Page>
            <FormActivationReset></FormActivationReset>
        </Page>        
    )
}