// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// import {} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const quillModules = {
    toolbar: [          
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],                      
    ],
}
// ----------------------------------------------------------------------

export default (props) => {
    const handleOnChange = (event) => {        
        if (props.onChange)
            props.onChange(event)
    }

    return (
        <ReactQuill
            theme="snow" 
            modules={quillModules} 
            value={props.data[props.fieldId]}                                                
            onChange={(value) => handleOnChange({target: {name: props.fieldId, value: value}})}
            // handleOnChange
        />
    )
}