// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI LAB ----
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Page from '../../components/gate/page'
import FormSignIn from '../../components/gate/formSignIn'
// ----------------------------------------------------------------------

export default (props) => {
    const { settings } = props
    return (    
        <Page>
            <FormSignIn settings={settings}></FormSignIn>
        </Page> 
    )
}