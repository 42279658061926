// --------------------------------------------------------- REACT ------
import { React, useState } from "react"
import { passwordStrength } from 'check-password-strength'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Stack,

    Button,
    IconButton,
    TextField,    

    Dialog,
    DialogContent,
    DialogTitle,

    Alert,
    Collapse,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI LAB ----
import {
    LoadingButton,    
} from '@mui/lab/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import { useAuthState } from '../authProvider';
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const API_URL_ME = "/api/me/"
// ----------------------------------------------------------------------

export default function DialogChangePassword(props) {
    const {open, dialogState, setDialogState } = props
    const {accessToken } = useAuthState()

    const formDefault = {
        disabled: false,
        loading: false,
        mainButtonDisabled: true,
        error: false,    
        errorText: "",   
        passwordError: false,
        passwordErrorText: "",                             
        newpasswordError: false,
        newpasswordErrorText: "",                             
        newpasswordverifyError: false,
        newpasswordverifyErrorText: "",                
    }

    const [form, setForm] = useState(formDefault)    
        
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return   

        setDialogState({ ...dialogState, open: false })
        setForm (formDefault)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        setForm({ ...formDefault, loading: true, error: false, disabled: true })

        // const requestOptions =   
    
        fetch('/api/me/password', {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
            body: JSON.stringify({
                password: data.get("password"),
                newpassword: data.get("newpassword")
            }),
        })
        .then((data) => {
            if (data.ok) {                
                handleClose ()
            } else {
                return data.json ()            
            }
        })        
        .then((data) => {
            if (data != null) {
                if (data.error != null)
                    throw new Error (data.error.code)                
                else 
                    throw new Error ("ER_UNKNOWN")
            }
        })
        .catch((error) => {                
            handleError (error)
        })
    }

    const handleError = (error) => {
        setForm({ ...form, loading: false, error: false, disabled: false })

        if (error.message == "ER_INVALID_USERNAME_PASSWORD")
            setForm({ ...form, error: true, errorText: "Current password is invalid." })
        else
            setForm({ ...form, error: true, errorText: "An unknown error occurred." })        
    }
    
    const onChange = (event) => {
        const data = new FormData(event.currentTarget)

        let formState = {...formDefault}
        
        // Validate: PASSWORD
        if (data.get("password").length == 0) {
            formState.passwordErrorText = "Current password needed"
            formState.passwordError = true
        }         

        // Validate: PASSWORD STRENGTH        
        if ((passwordStrength(data.get("newpassword")).value == "Too weak") && (data.get("newpassword").length > 0)) {
            formState.newpasswordErrorText = "Password is to weak"
            formState.newpasswordError = true
        } 

        // Validate: PASSWORD MATCH
        if (data.get("newpassword") != data.get("newpasswordverify") && (data.get("newpasswordverify").length > 0)) {
            formState.newpasswordverifyErrorText = "Passwords does not match"
            formState.newpasswordverifyError = true    
        } 

        if ((data.get("password").length > 0) && (data.get("newpassword").length > 0) && (data.get("newpasswordverify").length > 0))
            formState.mainButtonDisabled = !(!formState.passwordError && !formState.newpasswordError && !formState.newpasswordverifyError)
        else 
            formState.mainButtonDisabled = true

        setForm(formState)
    }

    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
            Change password
        </DialogTitle>
        <DialogContent>            
            <Box component="form" noValidate onSubmit={handleSubmit} onChange={onChange}>
                    <Collapse in={form.error}>
                        <Alert variant="filled" severity="error" 
                            action={
                                <IconButton aria-label="close" color="inherit" size="small"
                                    onClick={() => {
                                        setForm({ ...form, error: false })
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {form.errorText}
                        </Alert>
                    </Collapse>
                    <TextField      
                        required           
                        autoFocus                      
                        margin="dense"
                        name="password"
                        label="Current Password"
                        fullWidth
                        type="password"
                        variant="standard"                        
                        disabled={form.disabled}
                        error={form.passwordError}
                        helperText={form.passwordErrorText}                        
                    />         
                    <TextField                    
                        required
                        margin="dense"
                        name="newpassword"
                        label="New Password"            
                        type="password"
                        fullWidth
                        variant="standard"
                        inputProps={{
                            autoComplete: "new-password",
                        }}
                        disabled={form.disabled}
                        error={form.newpasswordError}
                        helperText={form.newpasswordErrorText}
                    />
                    <TextField                    
                        required
                        margin="dense"
                        name="newpasswordverify"
                        label="Verify New Password"            
                        type="password"
                        fullWidth
                        variant="standard"
                        inputProps={{
                            autoComplete: "new-password",
                        }}
                        disabled={form.disabled}                                            
                        error={form.newpasswordverifyError}
                        helperText={form.newpasswordverifyErrorText}
                    />
                    <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                        <Stack direction="row" spacing={2}>                                
                            <Button variant="outlined" onClick={handleClose}>Close</Button>
                            <LoadingButton 
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={form.mainButtonDisabled || form.disabled}
                                loading={form.loading}  
                                >
                                    Save
                            </LoadingButton>                             
                        </Stack>                   
                    </Box>
                </Box>            
            </DialogContent>            
        </Dialog>
    )
}