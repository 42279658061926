// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import { 
    DataGrid 
} from '@mui/x-data-grid'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

export default (props) => {  
    const FormStateDefault = {
        disabled: false,
        button1Disabled: false,
        button2Disabled: true
    }

    const [formState, setFormState] = useState(FormStateDefault)  
    const [rows, setRows] = useState([])  
    const [selectionModel, setSelectionModel] = React.useState([])     

    useEffect(async () => {
        if (props.open) {

            if (props.fetchRows)
                setRows(await props.fetchRows())
            else 
                setRows(props.rows)

            setFormState(FormStateDefault)
            setSelectionModel([])
        }
    }, [props.open])

    const handleSelection = (selection) => {
        setSelectionModel(selection)
                    
        if (selection.length != 0)
            setFormState({...formState, button2Disabled: false})
        else
            setFormState({...formState, button2Disabled: false})        
    }
    
    const handleButton1Click = () => {
        handleClose()

        if (props.onCancel)
            props.onCancel()
    }

    const handleButton2Click = () => {        
       handleSelect()        
    }
   
    const handleSelect = () => {
        if (props.onSelect != null)             
            props.onSelect(selectionModel)

        handleClose()
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return   
        
        props.setOpen(false)        
    }

    return (
        <Dialog 
            open={props.open}    
            
            fullWidth={true} 
            maxWidth={'md'}
            
            onClose={handleClose}            
        >
            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent 
                sx={{height: "300px"}}
            >
                <DataGrid                        
                    headerHeight={0}
                    columns={props.columns}
                    rows={rows}
                    rowsPerPageOptions={[100]}
                    hideFooter={true}                        
                    disableColumnMenu                         
                    onRowSelectionModelChange={handleSelection}
                    selectionModel={selectionModel}
                />                
            </DialogContent>
            <DialogActions 
                sx={{mr:2, mb: 2}}
            >
                {props.button1Text &&
                <Button 
                    variant="outlined" 
                    
                    onClick={handleButton1Click}

                    disabled={formState.button1Disabled}
                >
                    {props.button1Text}
                </Button>
                }
                <Button 
                    variant="contained" 
                    color="primary" 
                    
                    onClick={handleButton2Click} 
                    
                    disabled={formState.button2Disabled}
                >
                    {props.button2Text}
                </Button>
            </DialogActions>
        </Dialog>
    )
}