import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'

import './simpleUI/index.css'

import 'material-icons/iconfont/material-icons.css';


import {PublicLayout, ProtectedLayout} from './layouts.js'

import {
    SimpleUIUserGroups,

    SimpleUIApp,

    SimpleUIPageGateSignIn,
    SimpleUIPageGatePasswordSet,
    SimpleUIPageGatePasswordForgot,
    SimpleUIPageGateActivation,
    SimpleUIPageGateActivationReset,
    
    SimpleUIPageUsers
} from './simpleUI'

import EntityTypes from "./pages/entitytypes"
// import EntityTypesIcon from '@mui/icons-material/Schema'

import EntityTypesEdit from "./pages/entitytypes/edit/"

import Entities from "./pages/entities"
// import EntitiesIcon from '@mui/icons-material/SettingsInputAntenna'

import EntitiesEdit from "./pages/entities/edit"

// import UserTypesIcon from '@mui/icons-material/Groups'
import UserTypes from "./pages/usertypes"

// import PermissionGroupsIcon from '@mui/icons-material/Key'
import PermissionGroups from "./pages/permissiongroups"

// import DashboardsIcon from '@mui/icons-material/Dashboard'
import Dashboards from "./pages/dashboards"

import Workers from "./pages/workers"
import WorkersEdit from "./pages/workers/edit"

import Navigation from "./components/navigation"

const T = () => {
    return (<Fragment></Fragment>)
}


const settings = {
    useAuthentication: true,
    useEmailAsUsername: true,
    hideAppBar: false,
    hideNavBar: false,

    routes: [
        {
            path: "/",
            public: {            
                element: SimpleUIPageGateSignIn,
            },
            protected: {            
                element: T
                // navigation: {
                //     icon: EntitiesIcon,
                //     label: "EntitiesEdit"
                // }
            }
        },
        {
            path: "/resetpassword",
            public: {            
                element: SimpleUIPageGatePasswordSet,            
            },
        },
        {
            path: "/forgotpassword",
            public: {            
                element: SimpleUIPageGatePasswordForgot,            
            },
        },
        {
            path: "/activation",
            public: {
                element: SimpleUIPageGateActivation,            
            },
        },
        {
            path: "/resetactivation",
            public: {
                element: SimpleUIPageGateActivationReset,            
            },
        },
    
        Navigation,
            
        // Entities
        {
            path: "/entities/",            
            protected: {            
                element: Entities,
                navigation: {
                    icon: "settings_input_antenna",
                    label: "EntitiesEdit",
                    sort: 999
                }
            }
        },

        {
            path: "/entities/:entityId",
            protected: {
                element: EntitiesEdit,
                props: {
                    key: "entity-edit-0"
                },
            }
        },
                   
        // TYPES
        {
            path: "/types/",
            protected: {
                key: "list-types",
                element: EntityTypes,            
                navigation: {
                    icon: "schema",
                    label: "Types",
                    sort: 1000
                },
                restrictions: {
                    usergroups: [
                       SimpleUIUserGroups.ADMINISTRATORS.id
                    ]
                }
            }        
        },
    
        {
            path: "/types/:typeId",
            protected: {
                element: EntityTypesEdit,
                props: {
                    key: "entity-edit-0"
                },
                restrictions: {
                    usergroups: [
                       SimpleUIUserGroups.ADMINISTRATORS.id
                    ]
                }
            }        
        },
    
        // USERTYPES
        {
            path: "/usertypes/",
            protected: {
                key: "usertypes",
                element: UserTypes,            
                navigation: {
                    icon: "groups",
                    label: "Usertypes",
                    sort: 1001
                },
                restrictions: {
                    usergroups: [
                       SimpleUIUserGroups.ADMINISTRATORS.id
                    ]
                }
            }        
        },
    
        // PERMISSIONGROUPS
        {
            path: "/permissiongroups/",
            protected: {
                key: "permissiongroups",
                element: PermissionGroups,            
                navigation: {
                    icon: "key",
                    label: "PermissionGroups",
                    sort: 1002
                },
                restrictions: {
                    usergroups: [
                       SimpleUIUserGroups.ADMINISTRATORS.id
                    ]
                }
            }
        },
    
        // DASHBOARDS
        {
            path: "/dashboards/",
            protected: {
                key: "dashboards",
                element: Dashboards,            
                navigation: {
                    icon: "dashboard",
                    label: "Dashboards",
                    sort: 1003
                },
                restrictions: {
                    usergroups: [
                       SimpleUIUserGroups.ADMINISTRATORS.id
                    ]
                }
            }
        },

        // WORKERS
        {
            path: "/workers/",
            protected: {
                key: "workers",
                element: Workers,            
                navigation: {
                    icon: "miscellaneous_services",
                    label: "Workers",
                    sort: 1004
                },
                restrictions: {
                    usergroups: [
                       SimpleUIUserGroups.ADMINISTRATORS.id
                    ]
                }
            }
        },
        {
            path: "/workers/:workerId",
            protected: {
                element: WorkersEdit,
                props: {
                    key: "worker-edit-0"
                },
                restrictions: {
                    usergroups: [
                       SimpleUIUserGroups.ADMINISTRATORS.id
                    ]
                }
            }        
        },
    ]
}

const layouts = {
    public: PublicLayout,
    protected: ProtectedLayout
}

ReactDOM.render(
    <React.StrictMode>
        <SimpleUIApp layouts={layouts} settings={settings}/>
    </React.StrictMode>,
    document.getElementById('root')
)