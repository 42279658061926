// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Fade,
    Stack,
    IconButton,    
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import { DataGrid } from '@mui/x-data-grid'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {    
    Delete as RemoveIcon,     
    CloudDownload as DownloadIcon,
    CloudUpload as UploadIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

export default (props) => {
    const [dataGridColumns, setDataGridColumns] = useState([
        { field: 'originalname', headerName: 'Filename', flex: 1 },                 
        { field: 'mimetype', headerName: 'Filetype', flex: 0.3 },
        { field: 'filename', headerName: 'Test', width: 100, storable: false,
            renderCell: (params) => {
                const { row } = params
                const handleRemoveFile = () => {                    
                    row.callbackRemove(row.filename)
                }

                const handleDownloadFile = () => {                    
                    row.callbackDownload(row.filename)
                }

                return (
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                        
                            <IconButton aria-label="download" size="small" onClick={handleDownloadFile} disabled={row.fileNotFound}>
                                <DownloadIcon fontSize="inherit"/>
                            </IconButton>
                        
                        <IconButton aria-label="delete" size="small" onClick={handleRemoveFile}>
                            <RemoveIcon fontSize="inherit"/>
                        </IconButton>
                    </Stack>
                )
            }           
        },
    ])
    const [dataGridRows, setDataGridRows] = useState([])
    // const [dataGridSelectionModel, setDataGridSelectionModel] = useState([])
    const dataGridGetRowId = (row) => {
        return row.filename
    }
   
    useEffect(() => {        
        if (!Array.isArray(props.meta))
            return

        let newDataGridRows = []        
        // console.log (props.meta)
        // if (Array.isArray(props.meta)) {


            
            // for (let index = 0; index < props.data[props.fieldId].length; index++) {            
            //     const storageId = props.data[props.fieldId][index]           
            //     console.log (storageId)     
                
            //     let row = props.meta.find((element) => element.filename === storageId)
                    
            //     if (!row)
            //         row = {originalname: "Error: File could not be found.", filename: storageId, fileNotFound: true}
            //         // continue
//1
            //     row.callbackDownload = props.onDownloadFile
            //     row.callbackRemove = props.onRemoveFile
            //     newDataGridRows.push(row)
            // }
        // }        
        for (const storageId of props.data[props.fieldId]) {
            let row = props.meta.find((element) => element.filename === storageId)
            if (!row)
                continue

            row.callbackDownload = props.onDownloadFile
            row.callbackRemove = props.onRemoveFile
            newDataGridRows.push(row)
        }

        setDataGridRows(newDataGridRows)        
    }, [props.meta])   
        
    return (
        <React.Fragment>                                              
            <Box position="relative" 
                sx={{                  
                    height: '200px', 
                    width: '100%',                
                }}
            >                
                <DataGrid        
                    slots={{
                        columnHeaders: () => null,                    
                    }}
                    hideFooter={true}                    
                    columns={dataGridColumns} 
                    rows={dataGridRows}
                    // onRowSelectionModelChange={handleDataGridOnRowSelectionModelChange}
                    rowsPerPageOptions={[100]} 
                    // selectionModel={dataGridSelectionModel}
                    getRowId={dataGridGetRowId}
                    sx={{                        
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                    }}
                    
                />   
                                  
                {(props.formState.locked || props.formState.disabled)  &&
                    <Box position="absolute" left="0px" top="0px" width="100%" height="100%"
                        sx={{
                            backgroundColor: "rgba(255,255,255,0.3)"
                        }}
                    />                
                }

                <Fade in={(props.uploadProgress > 0 && props.uploadProgress < 100)}>
                    <Box 
                        position="absolute" 
                        left="0px" 
                        top="0px" 
                        width="100%" 
                        height="100%"
                        display="flex" 
                        justifyContent="center"
                        sx={{
                            alignItems: 'center', 
                            backgroundColor: "rgba(255,255,255,0.9)"
                        }}
                    >                    
                        <CircularProgress variant="determinate" value={props.uploadProgress} />
                    </Box>
                </Fade>
                <ButtonGroup variant="contained"
                fullWidth
                disableElevation
            >                                    
                <label htmlFor={`${props.fieldId}-file`}
                    style={{
                        display: "inline-block",
                        width: "100%"                 
                    }}  
                >
                    <Button 
                        sx={{                    
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                        }}
                        component="span"
                        color={'success'}                            
                        fullWidth
                        disabled={(props.formState.locked || props.formState.disabled || (props.uploadProgress > 0 && props.uploadProgress < 100))}
                    >
                        <UploadIcon />                            
                    </Button>        
                </label>
            </ButtonGroup>
            </Box>
            
        </React.Fragment>
    )    
}