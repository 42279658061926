// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Checkbox from './checkbox'
import Radio from './radio'
import Dropdown from './dropdown'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

export default (props) => {
    const handleOnChange = (event) => {        
        props.onChange({target: {name: props.fieldId, value: event.target.value}})
    }

    

    switch (props.type.fields[props.fieldId].subType) {
        case "checkbox": {
            return (
                <Checkbox
                    {...props}
                        
                    onChange={handleOnChange}
                />
            )
        }

        case "radio": {
            return (
                <Radio
                    {...props}
                        
                    onChange={handleOnChange}
                />
            ) 
        }

        case "dropdown": {
            return (
                <Dropdown
                    {...props}
                        
                    onChange={handleOnChange}                
                />
            )          
        }

        default: {
            return (<React.Fragment/>)
        }
    }
}