// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI LAB ----
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Page from '../../components/gate/page'
import FormPasswordForgot from '../../components/gate/formPasswordForgot'
// ----------------------------------------------------------------------

export default (props) => {
    const { settings } = props
    return (    
        <Page>
            <FormPasswordForgot></FormPasswordForgot>       
        </Page>
    )
}