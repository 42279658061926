// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,    
    Collapse,
    Typography,
    Alert,
    Button,
    TextField,
    IconButton,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI LAB ----
import {LoadingButton} from '@mui/lab/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import { passwordStrength } from 'check-password-strength'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const API_URL_ME_ACTIVATE = "/api/me/activation"
// ----------------------------------------------------------------------

export default (props) => {
    const [formState, setFormState] = useState({
        disabled: false,
        success: false,        
        loading: false,        
        error: false,    
        errorText: "",  
        passwordIsValid: false,
        passwordIsMatch: false, 
        tokenError: false,
        tokenErrorText: "",
        passwordError: false,
        passwordErrorText: "",
        passwordVerifyError: false,
        passwordVerifyErrorText: "",
        submitButtonDisabled: true,
    })        

    const handleSubmit = async (event) => {
        const queryParameters = new URLSearchParams(window.location.search)
        const accessToken = queryParameters.get("t")        
        const formData = new FormData(event.currentTarget)

        event.preventDefault()

        setFormState({ ...formState, 
            error: false,
            loading: true, 
            disabled: true             
        })

        fetch(API_URL_ME_ACTIVATE, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
            body: JSON.stringify({
                password: formData.get("password"),
            }),
        })
        .then((data) => {
            if (data.ok) {                
                setFormState({ ...formState, success: true })
            } else {
                return data.json ()            
            }
        })        
        .then((data) => {
            if (data != null) {
                if (data.error != null)
                    throw new Error (data.error.code)
                else 
                    throw new Error ("ER_UNKNOWN")
            }
        })
        .catch((error) => {                
            handleError (error)
        })
    }
    
    const handleOnChange = (event) => {
        const formData = new FormData(event.currentTarget)

        let newFormState = {...formState, 
            error: false,
            errorText: ""
        }

        // Validate: PASSWORD STRENGTH
        if (passwordStrength(formData.get('password')).value != "Too weak") {
            newFormState.passwordIsValid = true            
            newFormState.passwordError = false
            newFormState.passwordErrorText = ""            
        } else if (formData.get('password').length > 0) {
            newFormState.passwordIsValid = false
            newFormState.passwordError = true
            newFormState.passwordErrorText = "Password is to weak"            
        }

        // Validate: PASSWORD MATCH
        if (formData.get('password') == formData.get('passwordverify')) {            
            newFormState.passwordIsMatch = true
            newFormState.passwordVerifyError = false    
            newFormState.passwordVerifyErrorText = ""
        } else if (formData.get('passwordverify').length > 0) {
            newFormState.passwordIsMatch = false
            newFormState.passwordVerifyError = true    
            newFormState.passwordVerifyErrorText = "Passwords does not match"
        }
        
        newFormState.submitButtonDisabled = !(newFormState.passwordIsValid && newFormState.passwordIsMatch)

        setFormState(newFormState)
    }

    const handleError = (error) => {
        if (error.message == "ER_ACCESSTOKEN_EXPIRED")
            setFormState({ ...formState, loading: false, error: true, disabled: false, errorText: "AccessToken has expired, please request a new activation link."})
        else
            setFormState({ ...formState, loading: false, error: true, disabled: false, errorText: "An unknown error occurred."})        
    }

    return (
        <React.Fragment>
            <Collapse in={formState.success}>
                <Typography component="h1" variant="h5">
                    User account activated
                </Typography>      
                <Typography variant="body1" paragraph>
                    Account has been successfully activated.
                </Typography>                                                
                <Button href="/" variant="contained">Return to login</Button>
            </Collapse>

            <Collapse in={!formState.success}>
                <Typography component="h1" variant="h5">
                    Activate user account
                </Typography>
                <Typography variant="body">
                   To activate your new user account, please enter a valid password.
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} onChange={handleOnChange} sx={{ mt: 1 }}>
                    <Collapse in={formState.error}>
                        <Alert variant="filled" severity="error" 
                            action={
                                <IconButton aria-label="close" color="inherit" size="small"
                                    onClick={() => {
                                        setFormState({ ...formState, error: false })
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {formState.errorText}
                        </Alert>
                    </Collapse>
                    <TextField      
                        required           
                        autoFocus                      
                        margin="dense"
                        id="password"
                        name="password"
                        label="Password"
                        fullWidth
                        type="password"
                        variant="standard"                        
                        inputProps={{
                            autoComplete: "new-password",
                        }}
                        disabled={formState.disabled}
                        error={formState.passwordError}
                        helperText={formState.passwordErrorText}                        
                    />         
                    <TextField                    
                        required
                        margin="dense"
                        id="passwordverify"
                        name="passwordverify"
                        label="Verify Password"            
                        type="password"
                        fullWidth
                        variant="standard"
                        inputProps={{
                            autoComplete: "new-password",
                        }}
                        disabled={formState.disabled}
                        error={formState.passwordVerifyError}
                        helperText={formState.passwordVerifyErrorText}
                    />    
                    <LoadingButton 
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={formState.submitButtonDisabled || formState.disabled}
                        loading={formState.loading}  
                        >
                            Activate
                    </LoadingButton>
                </Box>
            </Collapse>
        </React.Fragment>
    )
}