import React from "react"


import { passwordStrength } from 'check-password-strength'
// MUI
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DialogContentText from '@mui/material/DialogContentText'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import PasswordIcon from '@mui/icons-material/Password';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

// OTHER
import {SideBarNavState, Width as drawerWidth} from "./sidebarnav"
import ConfirmDialog from "./DialogConfirm"
import DrawerAccount from "./account/"

import { useAuthState, useAuthDispatch, authSignOut, authRefreshTokens} from './authProvider';

const MuiAppBarStyled = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open'})
    (
        ({ theme, open }) => ({
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
}))





function UserMenu(params) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {        
        setAnchorEl(null)
    }

    const handleAccount = () => {                        
        params.handleAccountEdit ()    
        handleClose()        
    }        

    const handleLogout = () => {
        params.handleSignOut ()    
        handleClose()        
    }        
      
    return (        
        <div>                
             
            
            
            <IconButton color="inherit"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}            
            >
                <AccountCircleIcon />
            </IconButton>                    
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >   
                <MenuItem key={1} onClick={handleAccount}>
                    <ListItemIcon>
                        <AccountBoxIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Account</ListItemText>                                
                </MenuItem>                            

                <MenuItem key={2} onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>                                
                </MenuItem>

            </Menu>
        </div>
    )
}

function AppBarContent(props) {        
    const { settings } = props
    
    const { open, toggleDrawer } = SideBarNavState ()

    const dispatch = useAuthDispatch()
    const [dialogState, setDialogState] = React.useState({
        open: false,        
        title: "",
        children: "",
        button1Text: "No",
        button2Text: "Yes",      
        onConfirm: null
    })

    const { user, refreshToken } = useAuthState()    
    const [drawerAccountState, setDrawerAccountState] = React.useState({
        open: false        
    })

    const handleAccountEdit = () => {
        
        setDrawerAccountState({ ...drawerAccountState, open: true })
    }    

    const refresh = () => {

        authRefreshTokens(dispatch, refreshToken)      

    }

    const handleSignOut = () => {
        
        setDialogState({
            open: true,
            title: "Logout",
            children: (
                <DialogContentText id="alert-dialog-description">
                    Do you really want logout?
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: () => {
                authSignOut(dispatch, {})      
            },
            onDecline: () => {}
        })        
    }    
    
    
    return (
        <MuiAppBarStyled position="absolute" open={open}  elevation={0}>
            <ConfirmDialog open={dialogState.open} dialogState={dialogState} setDialogState={setDialogState}/>            
            <DrawerAccount open={drawerAccountState.open} setOpen={setDrawerAccountState} onSave={refresh} userId={user.userId}/>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
                >
                <IconButton onClick={toggleDrawer}
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    
                    sx={{
                        marginRight: '36px',
                        ...(false && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >                                     
                </Typography>                           
                <Typography color="inherit"
                sx={{
                    marginTop: '4px',                  
                }}
                >
                    {user.name}
                </Typography>                
                <UserMenu handleAccountEdit={handleAccountEdit} handleSignOut={handleSignOut}/>
            </Toolbar>                    
        </MuiAppBarStyled>
    )
}

export default function AppBar(settings) {
    return <AppBarContent />
}