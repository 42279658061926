// --------------------------------------------------------- REACT ------
import React, { useCallback } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import { IMaskInput } from 'react-imask'
// ----------------------------------------------------------------------

const IPAddressCIDR = React.forwardRef((props, ref) => {
    const { onChange, onKeyDown, ...other } = props    
    let maskComplete = false

    return (
        <IMaskInput
            {...other}

            inputRef={ref}
            lazy={true}
            overwrite

            mask={"D1.D2.D2.D2\\/CIDR"}
            blocks={{
                D1: {
                    mask: [
                        // 1-9
                        {
                            mask: "#",
                            definitions: {
                                '#': /[1-9]/,                            
                            }
                        },

                        // 10-99
                        {
                            mask: "#0",
                            definitions: {
                                '#': /[1-9]/,                            
                            }
                        },
                            
                        // 100-199
                        {
                            mask: "#00",                             
                            definitions: {                                
                                '#': /[1-1]/,
                            }
                        },

                        // 201-249
                        {
                            mask: "XY0",
                            definitions: {                                
                                'X': /[2-2]/,
                                'Y': /[0-4]/,
                            }
                        },

                        // 250-254
                        {
                            mask: "XYZ",
                            definitions: {
                                'X': /[2-2]/,
                                'Y': /[5-5]/,
                                'Z': /[0-4]/,
                            }
                        },
                    ]
                },

                D2: {
                    mask: [
                        // 1-9
                        {
                            mask: "0",
                            definitions: {
                                '#': /[1-9]/,                            
                            }
                        },

                        // 10-99
                        {
                            mask: "#0",
                            definitions: {
                                '#': /[1-9]/,                            
                            }
                        },
                            
                        // 100-199
                        {
                            mask: "#00",                             
                            definitions: {                                
                                '#': /[1-1]/,
                            }
                        },

                        // 201-249
                        {
                            mask: "XY0",
                            definitions: {                                
                                'X': /[2-2]/,
                                'Y': /[0-4]/,
                            }
                        },

                        // 250-254
                        {
                            mask: "XYZ",
                            definitions: {
                                'X': /[2-2]/,
                                'Y': /[5-5]/,
                                'Z': /[0-4]/,
                            }
                        },                                            
                    ],
                },



                CIDR: {
                    mask: [
                        // 1-9
                        {                            
                            mask: "0",
                            definitions: {
                                '#': /[1-9]/,
                            }
                        },                
                        
                        // 10-19
                        {
                            mask: "\\10",
                            definitions: {
                                '#': /[1]/,
                            }
                        },

                        // 20-29
                        {
                            mask: "\\20",
                            definitions: {
                                '#': /[1]/,
                            }
                        },

                        // 30-32
                        {
                            mask: "\\3#",
                            definitions: {
                                '#': /[1-2]/,
                            }
                        }
                    ]
                }
            }}
            
            onComplete={useCallback(value => {
                maskComplete = true                
            })}

            onAccept={useCallback(value => {
                maskComplete = false
                if (onChange)
                    onChange({ target: { name: props.name, value } })
            })}

            onKeyDown={useCallback((e) => {
                if (!maskComplete && e.keyCode != 8)
                    e.stopPropagation()
                
                if (maskComplete && onKeyDown)
                    onKeyDown(e)
            })}
        />
    )
})

const Pattern = React.forwardRef((props, ref) => {
    const { onChange, onKeyDown, ...other } = props    
    let maskComplete = false

    // console.log (other.pattern)

    return (
        <IMaskInput
            {...other}

            inputRef={ref}
            lazy={true}
            overwrite            

            mask={other.pattern}
            
            onComplete={useCallback(value => {
                maskComplete = true                
            })}

            onAccept={useCallback(value => {
                maskComplete = false
                if (onChange)
                    onChange({ target: { name: props.id || props.name, value } })
            })}

            onKeyDown={useCallback((e) => {
                if (!maskComplete && e.keyCode != 8)
                    e.stopPropagation()
                
                if (maskComplete && onKeyDown)
                    onKeyDown(e)
            })}
        />
    )
})

export { IPAddressCIDR, Pattern }
