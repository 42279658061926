// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

export default (props) => {
    // console.log (props.type.fields[props.fieldId].config)
    return (
        <FormControl 
            required={props.type.fields[props.fieldId].required}
            disabled={(props.formState.locked || props.formState.disabled)}
            fullWidth={true}
        >
            <InputLabel id={`${props.type.fields[props.fieldId]}-label`}>
                {props.type.fields[props.fieldId].title}
            </InputLabel>
            <Select
                labelId={`${props.type.fields[props.fieldId]}-label`}
                value={props.data[props.fieldId]}                
                label={props.type.fields[props.fieldId].title}
                multiple={(Array.isArray(props.data[props.fieldId]))}
                onChange={props.onChange}
                
            >
                {props.type.fields[props.fieldId].config.options.map((option, i) => {
                    return (
                        <MenuItem 
                            key={i}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )    
}