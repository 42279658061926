// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Radio,
    RadioGroup,    
    FormLabel,
    FormControl,
    FormControlLabel
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

export default (props) => {
    return (
        <FormControl
            sx={{
                m: 1
            }}

            required={props.type.fields[props.fieldId].required}
            disabled={(props.formState.locked || props.formState.disabled)}
        >
            <FormLabel id={`${props.type.fields[props.fieldId]}-label`}>
                {props.type.fields[props.fieldId].title}
            </FormLabel>
            <RadioGroup
                aria-labelledby={`${props.type.fields[props.fieldId]}-label`}
                row={true}                
                value={props.data[props.fieldId]}
                name={`${props.type.fields[props.fieldId]}-group`}
                onChange={props.onChange}
            >
                {props.type.fields[props.fieldId].options.map((option, i) => {
                    return (
                        <FormControlLabel 
                            key={i}
                            value={option.value} 
                            control={<Radio />} 
                            label={option.title}
                        />
                    )
                })}        
            </RadioGroup>
        </FormControl>            
    )    
}