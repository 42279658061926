// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Collapse,    
    Typography,
    Alert,
    Button,
    TextField,
    IconButton,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI LAB ----
import {LoadingButton} from '@mui/lab/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import emailValidator from 'email-validator'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const API_URL_ME_ACTIVATE_RESET = "/api/me/activation/reset"
// ----------------------------------------------------------------------

export default (props) => {
    const [formState, setFormState] = useState({
        success: false,
        disabled: false,
        loading: false,
        submitButtonDisabled: true,
        error: false,    
        errorText: "",
    })    

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)

        setFormState({ ...formState, loading: true, error: false, disabled: true })

        fetch(API_URL_ME_ACTIVATE_RESET, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',                
            },
            body: JSON.stringify({
                email: formData.get("email"),                
            }),
        })
        .then((data) => {
            if (data.ok) {                
                setFormState({ ...formState, success: true })
            } else {
                return data.json ()            
            }
        })        
        .then((data) => {
            if (data != null) {
                if (data.error != null)
                    throw new Error (data.error.code)                
                else 
                    throw new Error ("ER_UNKNOWN")
            }
        })
        .catch((error) => {                
            handleError (error)
        })        
    }
    
    const handleOnChange = (event) => {
        const formData = new FormData(event.currentTarget)
        const newFormState = {...formState}

        if (emailValidator.validate(formData.get('email')))
            newFormState.submitButtonDisabled = false
        else
            newFormState.submitButtonDisabled = true

        setFormState(newFormState)
    }

    const handleError = (error) => {
        setFormState({ ...formState, error: true, errorText: "An unknown error occurred." })        
    }

    return (
        <React.Fragment>
            <Collapse in={formState.success}>
                <Typography component="h1" variant="h5">
                    Activation link
                </Typography>      
                <Typography variant="body1" paragraph>
                    If the specified email addresse is connected to a valid user that has not yet been activated, an email will be send containing a link for activation.
                </Typography>                        
                <Typography variant="body1" paragraph>
                    If you do not receive this email within 5 minutes, and you are sure it belongs to a valid user. Check your spam folder. 
                </Typography>                        
                <Button href="/" variant="contained">Return to login</Button>       
            </Collapse>

            <Collapse in={!formState.success}>                
                <Typography component="h1" variant="h5">
                    Request activation link
                </Typography>      
                <Box component="form" noValidate onSubmit={handleSubmit} onChange={handleOnChange} sx={{ mt: 1 }}>
                    <Collapse in={formState.error}>
                        <Alert variant="filled" severity="error" 
                            action={
                                <IconButton aria-label="close" color="inherit" size="small"
                                    onClick={() => {
                                        setFormState({ ...formState, error: false })
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {formState.errorText}
                        </Alert>
                    </Collapse>
                    <TextField                            
                        margin="normal"
                        required
                        fullWidth                            
                        label="Email"
                        name="email"                            
                        autoFocus                            
                        disabled={formState.disabled}                      
                    />                        
                    <LoadingButton 
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={formState.submitButtonDisabled || formState.disabled}
                        loading={formState.loading}  
                        >
                            Request link
                    </LoadingButton>                                                   
                </Box>
            </Collapse>
        </React.Fragment>
    )
}