// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Button,
    ButtonGroup,
    FormControl,    
    TextField
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Delete as RemoveIcon, 
    CloudDownload as DownloadIcon,
    CloudUpload as UploadIcon    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
export default (props) => {
    return (
        <React.Fragment>       
            <FormControl                 
                fullWidth={true}
                hiddenLabel={true}
            >                
                <ButtonGroup variant="contained"
                    fullWidth
                    disableElevation
                >                    
                    <TextField
                        label={props.type.fields[props.fieldId].title}                    
                        value={((props.data?.[props.fieldId]?.[0] && Array.isArray(props.meta)) ? (props.meta.find((e) => e.filename == props.data?.[props.fieldId]?.[0])?.originalname) : "")}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    ></TextField>

                    {((props.data?.[props.fieldId] || []).length == 0)
                    &&             
                        <label htmlFor={props.fieldId +"-file"}>
                            <LoadingButton 
                                component="span"
                                color={'success'}                            
                                sx={{
                                    width: "30px",
                                    height: "100%",
                                }}           

                                loading={(props.uploadProgress > 0 && props.uploadProgress < 100)}                                                
                                disabled={(props.formState.locked || props.formState.disabled)}
                            >
                                <UploadIcon />
                            </LoadingButton>
                        </label>
                    }
                    {((props.data?.[props.fieldId] || []).length > 0)
                    &&
                        <React.Fragment>
                            <Button                         
                                color={'primary'}                             
                                sx={{
                                    width: "30px"
                                }}   
                                
                                disabled={(props.formState.locked || props.formState.disabled || props.meta.fileNotFound || (props.uploadProgress > 0 && props.uploadProgress < 100))}
                                onClick={() => {props.onDownloadFile (null)}}
                            >
                                <DownloadIcon />
                            </Button>
                            <Button                         
                                color={'error'}                             
                                sx={{
                                    width: "30px"
                                }}   
                                
                                disabled={(props.formState.locked || props.formState.disabled || (props.uploadProgress > 0 && props.uploadProgress < 100))}
                                onClick={() => {props.onRemoveFile (props.data?.[props.fieldId]?.[0])}}
                            >
                                <RemoveIcon />
                            </Button>
                        </React.Fragment>
                    }               
                </ButtonGroup>
            </FormControl>
        </React.Fragment>
    )    
}