// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Paper,    
    Stack,
    DialogContentText,
    IconButton,
    Collapse,
    Alert,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import { DataGrid } from '@mui/x-data-grid'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Refresh as RefreshIcon,    
    Edit as EditIcon,    
    Delete as DeleteIcon,
    Add as AddIcon,        
    Close as CloseIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {API_URL_SIMPLECRM} from '../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,    
    SimpleUIDialogConfirm,
    SimpleUIDrawer,
} from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import FormEdit from './edit/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const {user, accessToken} = SimpleUIAuthState()    

    // #region FORM
    const formDefaultState = {
        disabled: false,
        
        error: false,    
        errorText: "",        
    }

    const [formState, setFormState] = useState(formDefaultState)    
    const [toolbarState, setToolbarState] = useState({ create: true, delete: false, edit: false, refresh: true })

    useEffect(() => {
        handleRefresh ()            
    }, [])
       
    const handleCreate = () => {
        setDrawerCreateState({...drawerCreateState, open: true})        
    }

    const handleEdit = () => {
        setDrawerEditState({...drawerEditState, open: true})        
    }         

    const handleDelete = () => {
        setDialogDelete({
            open: true,
            title: "Warning",
            children: (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this permissiongroup?
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: async () => {
                try {
                    let fetchDelete = await fetch(`${API_URL_SIMPLECRM.PERMISSIONGROUPS}${dataGridSelectionModel[0]}`, {
                        method: 'DELETE', 
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer "+ accessToken
                        }
                    })  
        
                    if (!fetchDelete.ok)
                        throw new Error((await fetchDelete.json()).error.code)
        
                    handleRefresh()
                } catch (error) {
                    handleError(error)
                }                
            },
            onDecline: () => {}
        })
    }

    const handleRefresh = async () => {
        try {
            let fetchGet = await fetch(`${API_URL_SIMPLECRM.PERMISSIONGROUPS}`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!fetchGet.ok)
                throw new Error((await fetchGet.json()).error.code)

            setDataGridRows(await fetchGet.json())
        } catch (error) {            
            handleError(error)
        }        
    }    

    const handleError = (error) => {
        switch (error.message) {            
            case "ER_PERMISSIONGROUP_CANNOT_DELETE_A_REFERENCED_PERMISSIONGROUP": {
                setFormState({ ...formState, error: true, errorText: "Can not delete a PermissionGroup that is referenced. Please remove references and try again." })
                break
            }
            default: {
                setFormState({ ...formState, error: true, errorText: "Unexpected error occured." })
            }
        }

        console.log (error)
    }
    // #endregion

    // #region CREATE, EDIT
    const [drawerCreateState, setDrawerCreateState] = useState({ open: false })
    const [drawerEditState, setDrawerEditState] = useState({ open: false })
    // #endregion

    // #region DIALOGS
    const [dialogStateDelete, setDialogDelete] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })
    // #endregion

    // #region DATAGRID
    const [dataGridColumns, setDataGridColumns] = useState([
        { field: 'name', headerName: 'Name', flex: 1.0 }
    ])
    const [dataGridRows, setDataGridRows] = useState([])
    const [dataGridSelectionModel, setDataGridSelectionModel] = useState([]) 

    const handleDataGridOnRowSelectionModelChange = (selection) => {
        setDataGridSelectionModel(selection)            
        if (selection.length != 0)
            setToolbarState({ ...toolbarState, delete: true, edit: true })
        else
            setToolbarState({ ...toolbarState, delete: false, edit: false })
    }

    const handleDataGridClickAway = () => {}
    // #endregion

    return (
        <React.Fragment>
            <SimpleUIDialogConfirm dialogState={dialogStateDelete} setDialogState={setDialogDelete}/>
            <SimpleUIDrawer state={drawerCreateState} setState={setDrawerCreateState} callback={handleRefresh}>
                <FormEdit/>
            </SimpleUIDrawer>
            <SimpleUIDrawer state={drawerEditState} setState={setDrawerEditState} permissionGroupId={dataGridSelectionModel} callback={handleRefresh}>
                <FormEdit/>
            </SimpleUIDrawer>
            <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100%)',
                        width: '100%'
                    }}
                >
                <Collapse in={formState.error}>
                    <Alert variant="filled" severity="error" 
                        action={
                            <IconButton aria-label="close" color="inherit" size="small"
                                onClick={() => {
                                    setFormState({ ...formState, error: false })
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {formState.errorText}
                    </Alert>
                </Collapse>             
                <Stack spacing={2} direction="row" justifyContent="end" sx={{pb: '20px'}}>                                    
                    <IconButton color="primary" aria-label="create" onClick={handleCreate} disabled={!toolbarState.create}>
                        <AddIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="edit" onClick={handleEdit} disabled={!toolbarState.edit}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="delete" onClick={handleDelete} disabled={!toolbarState.delete}>
                        <DeleteIcon />
                    </IconButton>                                    
                    <IconButton aria-label="refresh" onClick={handleRefresh} disabled={!toolbarState.refresh}>
                        <RefreshIcon />
                    </IconButton>
                </Stack>
                <DataGrid              
                    disableColumnMenu
                    columns={dataGridColumns} 
                    rows={dataGridRows}
                    onRowSelectionModelChange={handleDataGridOnRowSelectionModelChange}
                    rowsPerPageOptions={[100]} 
                    selectionModel={dataGridSelectionModel}                    
                />                
            </Paper>    
        </React.Fragment>    
    )
}