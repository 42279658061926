import jwt from 'jwt-decode'

const DEBUG = true
const API_URL_USER = "/api"
const REFRESH_INTERVAL = 250000
// const REFRESH_INTERVAL = 50000

var REFRESH_TIMER  



async function refreshService (dispatch, payload) {
    if (DEBUG) {
        console.debug ("AUTHENTICATION: Token refresh service")
        console.debug (payload.accessToken)   
        console.debug (payload.refreshToken)           
    }
    
    clearTimeout(REFRESH_TIMER)

    if (payload.accessToken) {        

        let currentDate = new Date()        

        if (((jwt(payload.accessToken).exp * 1000) < (currentDate.getTime())+(REFRESH_INTERVAL) )) {            
            const currentSession = await refreshTokens(dispatch, payload.refreshToken)
            payload.accessToken = currentSession.accessToken
            payload.refreshToken = currentSession.refreshToken
        }

        if (DEBUG) {
            var d = new Date(0)
            d.setUTCSeconds(jwt(payload.accessToken).exp)
            console.debug("AUTHENTICATION: ACCESSTOKEN EXPIRE : " + d)

            var d2 = new Date(0)
            d2.setUTCSeconds(jwt(payload.refreshToken).exp)
            console.debug("AUTHENTICATION: REFRESHTOKEN EXPIRE :  " + d2)

            var d3 = new Date(0)
            d3.setUTCSeconds((currentDate.getTime()+REFRESH_INTERVAL)/1000)
            console.debug("AUTHENTICATION: NEXT REFRESH : " + d3)
        
            // console.log (jwt(payload.accessToken).exp * 1000)
            // console.log (((currentDate.getTime())+(refreshInterval)))
            // console.log ((jwt(payload.accessToken).exp * 1000) - ((currentDate.getTime())+(refreshInterval)) )
            // console.log ("AUTHENTICATION: " ((jwt(payload.accessToken).exp * 1000) < (currentDate.getTime())+(refreshInterval) ))

            // var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            // d.setUTCSeconds(jwt(accessToken).exp);    
            // console.debug("Valid token: " + d); 
        }        
    }

    REFRESH_TIMER = setTimeout(() => refreshService(dispatch, payload), REFRESH_INTERVAL)
}

export async function initialize(dispatch, payload) {      
    if (DEBUG)
        console.debug ("AUTHENTICATION: Initializing")

    let user = localStorage.getItem("currentSession")
    ? JSON.parse(localStorage.getItem("currentSession")).user
    : ""
      
    let accessToken = localStorage.getItem("currentSession")
    ? JSON.parse(localStorage.getItem("currentSession")).accessToken
    : ""
      
    let refreshToken = localStorage.getItem("currentSession")
    ? JSON.parse(localStorage.getItem("currentSession")).refreshToken
    : ""

    let currentDate = new Date()
    if (accessToken != null && accessToken != "")
    {
        if (!(jwt(accessToken).exp * 1000 < currentDate.getTime())) {
            refreshService(dispatch, {accessToken: accessToken, refreshToken: refreshToken})
            dispatch({ type: 'AUTHORIZED', payload: {user: user, accessToken: accessToken, refreshToken: refreshToken} })
            return
        }
    }

    if (refreshToken != null && refreshToken != "") {        
        if (!(jwt(refreshToken).exp * 1000 < currentDate.getTime())) {                                                        
            const currentSession = await refreshTokens(dispatch, refreshToken)
            console.log ("-1")
            refreshService(dispatch, {accessToken: currentSession.accessToken, refreshToken: currentSession.refreshToken})
            return
        } 
    }     
    
    dispatch({ type: 'UNAUTHORIZE', payload: {} })
}

export async function signIn(dispatch, loginPayload) {
    if (DEBUG)
        console.debug ("AUTHENTICATION: signin")

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginPayload),
    }

    let response = await fetch(API_URL_USER +"/auth/signin", requestOptions)
    let data = await response.json()
    
    if (response.status != 201)
        throw new Error (data.error.code)                
        
    let currentSession = {        
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        user: jwt(data.accessToken),
    }

    refreshService(dispatch, {accessToken: data.accessToken, refreshToken: data.refreshToken})
    localStorage.setItem('currentSession', JSON.stringify(currentSession))     
    dispatch({ type: 'AUTHORIZED', payload: {accessToken: data.accessToken, refreshToken: data.refreshToken, user: jwt(data.accessToken)} })
}
 
export async function signOut(dispatch) {
    localStorage.removeItem('currentSession')
    clearTimeout(REFRESH_TIMER)
    dispatch({ type: 'UNAUTHORIZE' })
}

export async function refreshTokens(dispatch, refreshToken) {
    if (DEBUG)
        console.debug ("AUTHENTICATION: Refreshing tokens")

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            refreshtoken: refreshToken
        }),
    }    
    
    let response = await fetch(API_URL_USER +"/auth/refreshtoken", requestOptions)    
    let data = await response.json()
    
    
    // if (response.status != 201)
        // throw new Error (data.error.code)                
        
    let currentSession = {        
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        user: jwt(data.accessToken)
    }

    localStorage.setItem('currentSession', JSON.stringify(currentSession))         

    if (REFRESH_TIMER != null)
    {
        if (DEBUG)
            console.debug ("AUTHENTICATION: Renewing REFRESHSERVICE")

        clearTimeout(REFRESH_TIMER)
        REFRESH_TIMER = setTimeout(() => refreshService(dispatch, {accessToken: currentSession.accessToken, refreshToken: currentSession.refreshToken}), REFRESH_INTERVAL)
    }

    dispatch({ type: 'AUTHORIZED', payload: currentSession})

    return currentSession
}
