// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {    
    TextField,    
    Chip,
    Autocomplete,
    CircularProgress,    
} from '@mui/material/'
// ----------------------------------------------------------------------

export default (props) => {   
    const fixedOptions = props.fixedOptions || []
    const [options, setOptions] = React.useState([])

    const [open, setOpen] = React.useState(false)
    const loading = open && options.length === 0 
    
    let t

    if (props.freeSolo)
        t = props.value
    else
        t = fixedOptions.concat(props.value).filter((v,i,a)=>a.findIndex(v2=>(v2[props.optionsKeyField]===v[props.optionsKeyField]))===i) 
        
    
    const [value, setValue] = React.useState(t)

    

    React.useEffect(() => {
        let active = true    
        if (typeof props.options === "function") {
            if (!loading) 
                return undefined
          
            props.options ()                
            .then((data) => {            
                if (active) 
                    setOptions([...data])
            })                
                
            return () => {
                active = false
            }
        } else if (props.options)  {
            setOptions([...props.options])
        } else {
            setOptions([])
        }        
    }, [loading])
    
    React.useEffect(() => {
        if (!open)
            setOptions([])
    }, [open])

    const handleOnChange = (e, value) => {
        let event = {target: {id: props.id, name: props.name, value: value}}

        if (typeof props.onChange === "function")
            props.onChange(event)
    }

    return (    
        <Autocomplete          
            sx={props.sx}
        
            value={value}    

            multiple
            freeSolo={props.freeSolo}
            size={props.size}

            onChange={(event, newValue, reason) => {
                if (props.disableBackspaceRemoveOption && reason == "removeOption" && event.keyCode == 8)
                    return
                
                let tempValue = [
                    ...fixedOptions,
                    ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
                ]
                
                setValue(tempValue)        
                handleOnChange (event, tempValue)
            }}

            open={open}            
            onOpen={() => {
                if (props.options)
                    setOpen(true)
            }}
            onClose={() => {
                if (props.options)
                    setOpen(false)
            }}        

            loading={loading}
            loadingText={props.loadingText}
                    
            options={options}
            noOptionsText={props.noOptionsText}

            getOptionLabel={(option) => {
                if (props.optionsLabelField)
                    return option[props.optionsLabelField]
                else
                    return option
            }}
                
            isOptionEqualToValue={(option, value) => {
                if (props.optionsLabelField)
                    return option[props.optionsKeyField] === value[props.optionsKeyField]
                else
                    return option === value
            }}
                            
            renderTags={(tagValue, getTagProps) => {
                return (
                    tagValue.map((option, index) => {
                        if (props.optionsLabelField) {
                            return (
                                <Chip
                                    label={option[props.optionsLabelField]}
                                    size={props.size}
                                    {...getTagProps({ index })}
                                    disabled={fixedOptions.indexOf(option) !== -1}
                                />
                            )
                        } else {
                            return (
                                <Chip
                                    label={option}
                                    size={props.size}
                                    {...getTagProps({ index })}
                                    disabled={fixedOptions.indexOf(option) !== -1}
                                />
                            )
                        }
                    })
                )
            }}

            renderInput={(params) => (
                <TextField 
                    {...params} 
                    
                    label={props.label} 
                    variant={props.variant || "standard"}
                
                    InputProps={{ ...params.InputProps, inputComponent: props.inputComponent,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}                   
                />
            )}
        />
    )
}
