import React, {Fragment} from "react"
import {RouterProvider} from "react-router-dom"

import {
    appInitialize,     
    appUpdateRoutes,
    AppProvider, 
    useAppState, 
    useAppDispatch 
} from "./appProvider"

// Authentication
import {
    authInitialize,     
    AuthProvider, 
    useAuthState, 
    useAuthDispatch 
} from "./authProvider"


function App(props) {
    const {layouts, settings} = props

    const appDispatch = useAppDispatch()    
    const {appInitializing, router} = useAppState()

    const authDispatch = useAuthDispatch()
	const {authorized, authInitializing, user, accessToken} = useAuthState()

    React.useEffect(() => {
        appInitialize(appDispatch, {settings: settings, layouts: layouts})
        authInitialize(authDispatch, {})        
    }, [])

    React.useEffect(() => {        
        if (authorized === undefined)
            return 

        appUpdateRoutes(appDispatch, {user: user, accessToken: accessToken, authorized: authorized, layouts: layouts, settings: settings})
    }, [authorized, appInitializing])

    if (appInitializing || authInitializing || router == undefined) 
        return (<Fragment></Fragment>)

    return (
        <Fragment>
            <RouterProvider router={router} />
        </Fragment>
    )
}

export default function SimpleUIApp(props) {
    return (
        <AuthProvider>             
            <AppProvider>
                <App {...props}/>
            </AppProvider>
        </AuthProvider>           
    )
}