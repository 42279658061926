// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Collapse,    
    Box,
    Stack,
    Paper,
    Typography,
    Alert,
    Button,    
    TextField,            
    IconButton,    
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Tabs,
    Tab,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,    
    SimpleUIChipBox,

    SimpleUICommonReactSwitch,
    SimpleUICommonReactCase,

    SimpleUIMaskedInputPattern,
} from './../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {API_URL_SIMPLECRM} from '../../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import List from './list'
import Textarea from './textarea'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >   
        <div style={{ display: value === index ? 'block': 'none'}}>  
            {children}
        </div>
      </div>
    )
}

const Switch = (props) => {    
    const { condition, children } = props

    const result = React.Children.toArray(children).find(child => {
        return child.props.value === condition
      })

    if (result)
        return result
    else
        return (<React.Fragment></React.Fragment>)
  }

const Case = ({ children, value }) => {
    return children
}

export default (props) => {
    const { drawerState, setDrawerState, fieldId, type, callback } = props

    const { user, accessToken } = SimpleUIAuthState()

    // #region FORM
    const formDefaultState = {
        isInitializing: true,        
        disabled: false,                              
        mainButtonDisabled: true,
        mainButtonLoading: false,        
        error: false,    
        errorText: ""        
    }
    const formValueDefault = {}

    const [ formState, setFormState ] = useState(formDefaultState)       
    const [ formData, setFormData ] = useState(formValueDefault)
    const [ formDefaultData, setFormDefaultData ] = useState(formValueDefault)    

    // const [ formValid, setFormValid ] = useState()

    const [ userTypes, setUserTypes ] = useState([])
    const [ entityTypes, setEntityTypes ] = useState([])

    useEffect(async () => {
        // console.log (fieldId)
        if (fieldId) {            
            const cloneField = () => {
                let clone = structuredClone(type.fields[fieldId[0]])
                clone.id = fieldId[0]
                return clone
            }

            console.log (cloneField())

            setFormData(cloneField())
            setFormDefaultData(cloneField())
        } 

        setEntityTypes(await getEntityTypes())
        setUserTypes(await getUserTypes())
        
        // else {
        //     setFormData(formValueDefault)
        // }
    }, [drawerState.open])

    useEffect(() => {
        let valid = 0

        // Validate: CHANGED
        if (JSON.stringify({...formDefaultData}) === JSON.stringify({ ...formData})) {
            valid++
        }

        // Validate: LABEL
        if ((formData.label || "").length == 0) {
            valid++
        }
                
        // Validate: TYPE
        if ((formData.type || "") == "") {
            valid++
        }

        // Validate: TEXTFIELD
        if (formData.type == "textfield") {
            if ((formData.subType || "") == "")
                valid++
        }

        // Validate: UPLOAD
        if (formData.type == "upload") {
            if ((formData.subType || "") == "")
                valid++
        }

        // VALIDATE: USER
        if (formData.type == "user") {
            if (formData?.dataSource) {
                if ((formData.dataSource?.name || "") == "")
                    valid++

                if ((formData.dataSource?.email || "") == "")
                    valid++
            } 

            if ((formData.usertype || "") == "")
                valid++
        }

        // VALIDATE: LIST->JSON
        if (
            (formData.type == "list") &&
            (formData.subType == "json")
        ) {
            try {
                JSON.parse(formData?.config?.jsonSchema)
            } catch (error) {
                valid++
            }
        }

        // if (
        //     (formData.type == "select")
        // ) {
        //     try {
        //         JSON.parse(formData?.config?.options)
        //     } catch (error) {
        //         valid++
        //     }
        // }

        setFormState({...formState,
            isInitializing: false,
            mainButtonLoading: false,
            mainButtonDisabled: !!(valid),
            error: false,
            errorText: ""  
        })
        
    }, [formData])

    const handleOnChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value

        console.log (id +" "+ value)


        
        setFormData(prevState => {
            // const newState = {...prevState}


            const newState = structuredClone(prevState)
            const path = id.split('.')
            const last = path.pop()            
            path.reduce((ac,a) => ac[a], newState)[last] = value

            // TYPE CHANGE
            if (newState.type != prevState.type) {
                newState.subType = ""
                newState.required = false
                newState.unique = false
                newState.config = {}
            }

            

            // USER
            if (newState.type == "user" && newState.dataSource == null)
                newState.dataSource = {}

            return newState
        })

        return true
    }

    const handleOnClickMainButton = () => {
        callback({...formData})
        handleClose()        
    }

    const handleClose = () => {
        setFormState(formDefaultState)
        setFormData(formValueDefault)        
        setDrawerState({...drawerState, open: false})
    }

    const handleError = (error) => {
        setFormState({ ...formState, isInitializing: false })                     

        switch (error.message) {
            default: {
                setFormState({ ...formState, createLoading: false, error: true, errorText: "Der opstod en uventet fejl." })
            }
        }
    }
    // #endregion

    // #region TAB STATES
    // const [tab, setTab] = React.useState(0)    
    // const handleOnChangeTab = (event, newValue) => {
    //     setTab(newValue);
    // }
    
    // const [dialogEditAPICredentialsState, setDialogEditAPICredentialsState] = React.useState({
    //     open: false,
    //     data: null        
    // })

    // const dialogEditAPICrendentialsCallback = (data) => {
    //     let tempAPICredentials = [...formData.apiCredentials]
    //     let index = tempAPICredentials.findIndex(o => o.id == data.id);

    //     if (index > -1) 
    //         tempAPICredentials[index] = {...data}
    //     else 
    //         tempAPICredentials.push ({...data})
        
    //     setFormData({...formData, apiCredentials: tempAPICredentials})
    // }    

    // const handleOnAPICredentialAdd = (id) => {
    //     setDialogEditAPICredentialsState({...setDialogEditAPICredentialsState,
    //         open: true,
    //         data: {}
    //     })
    // }

    // const handleOnAPICredentialEdit = (id) => {
    //     setDialogEditAPICredentialsState({...setDialogEditAPICredentialsState,
    //         open: true,
    //         data: formData.apiCredentials.find(o => { return o.id === id})
    //     })
    // }

    // const handleOnAPICredentialDelete = (id) => {
    //     setFormData({...formData, apiCredentials: formData.apiCredentials.filter(o => { return o.id !== id})})
    // }

    // const handleonAPICredentialsCallback = (data) => {
    //     setFormData({...formData, apiCredentials: data})
    // }

    // const handleOnSelectionAPICredentials = (selection) => {}
    // const handleOnClickAwayAPICredentials = () => {}   

    // #endregion

    // #region FUNCTIONS
    const getUserTypes = async () => {
        let output = []    
        try {
            let getTypes = await fetch(API_URL_SIMPLECRM.USERTYPES, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getTypes.ok)
                throw new Error((await getTypes.json()).error.code)

            output = await getTypes.json()

            // console.log (output)
            
        } catch (error) {            
            handleError(error)
        }  

        return output
    }

    const getEntityTypes = async () => {
        let output = []    
        try {
            let getTypes = await fetch(API_URL_SIMPLECRM.TYPES, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getTypes.ok)
                throw new Error((await getTypes.json()).error.code)

            output = await getTypes.json()

            const index = output.findIndex(i => i.id === type.id)
            if (index > -1) {
                output.splice(index, 1)
            }
        } catch (error) {            
            handleError(error)
        }  

        return output
    }

    const getEntityRefs = async () => {
        let output = []    
        try {
            let getTypes = await fetch(API_URL_SIMPLECRM.TYPES, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getTypes.ok)
                throw new Error((await getTypes.json()).error.code)

            output = await getTypes.json()
        } catch (error) {            
            handleError(error)
        }  

        return output
    }

    const checkIfDatasourceIsInUseByType = (fieldId, useType, dataSourceType) => {
        let result = false
        Object.keys(type.fields || {}).map(function (key, i)  {            
            if (
                (key != fieldId) &&
                (type.fields[key].type == useType) &&
                (JSON.stringify(type.fields[key].dataSource).search(`"${dataSourceType}":"${fieldId}"`) > 0)
            ) {
                result = true
            }        
        })
        return result
    }
    // #endregion

    const test = () => {
        
        try {
            return JSON.stringify(formData?.config?.options || [], null, 4) 
        } catch (error) {
            return formData?.config?.options || []
        }

        
    }

    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    }     
    
    return (
        <React.Fragment>            
            <Collapse in={formState.error}>
                <Alert variant="filled" severity="error" 
                    action={
                        <IconButton aria-label="close" color="inherit" size="small"
                            onClick={() => {
                                setFormState({ ...formState, error: false })
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {formState.errorText}
                </Alert>
            </Collapse>  
            
            <Paper elevation={3} sx={{ p: 2}}>
            
                {/* OPTIONS */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="h6" sx={{mb: 1}}>
                        {formData.id ? "Edit field" : "Create field" }
                    </Typography>
                
                    <FormGroup row={true}>
                        <FormControlLabel control={
                            <Checkbox                                            
                                checked={!!formData.required ?? false}
                                disabled={
                                    (formState.disabled) ||                                
                                    
                                    (!formData.type) ||                                
                                    (formData.type == "ref") ||
                                    (formData.type == "stringformat") ||
                                    (formData.type == "user") ||
                                    
                                    (checkIfDatasourceIsInUseByType(fieldId, "user", "email"))
                                }
                                onChange={(event) => {handleOnChange({target: {name: "required", value: event.target.checked}})} }
                            />
                        } label="Required" />

                        <FormControlLabel control={
                            <Checkbox                                                                                        
                                checked={!!formData.unique ?? false}                                            
                                disabled={
                                    (formState.disabled) ||
                                    
                                    (!formData.type) ||
                                    (formData.type == "ref") ||
                                    (formData.type == "stringformat") ||
                                    (formData.type == "upload") ||
                                    (formData.type == "user") ||

                                    (formData.subType == "password") ||

                                    (checkIfDatasourceIsInUseByType(fieldId, "user", "email"))
                                }
                                onChange={(event) => {handleOnChange({target: {name: "unique", value: event.target.checked}})} }
                            />
                        } label="Unique" />
                    </FormGroup>  
                </Stack>                

                {/* ID */}
                {formData.id && (
                    <TextField sx={{mb: 1}}
                        margin="dense"                    
                        label="Id"
                        fullWidth                    
                        value={formData.id ?? ""}
                        InputProps={{
                            readOnly: true,
                        }}                    
                    />
                )}

                {/* LABEL */}
                <TextField sx={{mb: 1}}
                    required                                     
                    margin="dense"
                    id="label"
                    label="Label"
                    fullWidth                                        
                    autoComplete="off"
                    value={formData.label ?? ""}
                    disabled={formState.disabled}                    
                    onChange={handleOnChange}                    
                />

                {/* SLUG */}
                <TextField sx={{mb: 1}}
                    margin="dense"
                    id="slug"
                    label="Slug"
                    fullWidth                                        
                    autoComplete="off"
                    value={formData.slug ?? ""}
                    disabled={formState.disabled}
                    onChange={handleOnChange}    
                   
                    InputProps={{   
                        inputComponent: SimpleUIMaskedInputPattern,
                        inputProps: {
                            pattern: /^[A-Za-z_]+$/
                        }
                    }}                   
                />

                {/* TYPE / SUBTYPE */}
                <FormControl sx={{mt: 1, mb: 1}}
                    fullWidth 
                    required 
                >
                    <InputLabel>Type</InputLabel>
                    <Select                                  
                        label="Type"
                        name="type"
                        value={formData.type ?? ""}
                        onChange={handleOnChange}                        
                        disabled={(
                            (!!formData.id) || 
                            (formState.disabled)
                        )}
                    >   
                        <MenuItem key={1} value={"stringformat"}>StringFormat</MenuItem>
                        <MenuItem key={2} value={"textfield"}>TextField</MenuItem>                        
                        <MenuItem key={4} value={"select"}>Select</MenuItem>
                        <MenuItem key={10} value={"upload"}>Upload</MenuItem>
                        <MenuItem key={20} value={"user"}>User</MenuItem>
                        <MenuItem key={30} value={"list"}>List</MenuItem>
                        <MenuItem key={100} value={"ref"}>Reference</MenuItem>
                    </Select>
                </FormControl>

                <Switch condition={formData.type}>
                    <Case value={"textfield"}>
                        <FormControl sx={{mt: 1, mb: 1}}
                            fullWidth 
                            required 
                        >
                            <InputLabel>Subtype</InputLabel>
                            <Select
                                label="subType"
                                name="subType"
                                value={formData.subType ?? ""}
                                onChange={handleOnChange}
                                disabled={(
                                    (formState.disabled) ||
                                    (formData.hasRef)
                                )}
                            >       
                                <MenuItem key={1} value={"string"}>String</MenuItem>
                                <MenuItem key={10} value={"email"}>E-mail</MenuItem>
                                <MenuItem key={20} value={"richtext"}>Richtext</MenuItem>
                                {/* <MenuItem key={11} value={"password"}>Password</MenuItem>
                                <MenuItem key={25} value={"mask_ipv4"}>Mask: IPv4</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Case>

                    <Case value={"textarea"}>
                        <Textarea data={formData} setData={setFormData} type={type} formState={formState} setFormState={setFormState} onError={handleError}></Textarea>
                    </Case>   

                    <Case value={"select"}>
                        <FormControl sx={{mt: 1, mb: 1}}
                            fullWidth 
                            required 
                        >
                            <InputLabel>Subtype</InputLabel>
                            <Select
                                label="subType"
                                name="subType"
                                value={formData.subType ?? ""}
                                onChange={handleOnChange}
                                disabled={(
                                    (formState.disabled) ||
                                    (formData.hasRef)
                                )}
                            >       
                                <MenuItem key={1} value={"dropdown"}>Dropdown</MenuItem>
                                <MenuItem key={2} value={"radio"}>Radio</MenuItem>
                                <MenuItem key={3} value={"checkbox"}>Checkbox</MenuItem>                                
                            </Select>
                        </FormControl>
                    </Case>

                    <Case value={"upload"}>
                        <FormControl sx={{mt: 1, mb: 1}}
                            fullWidth 
                            required 
                        >
                            <InputLabel>Subtype</InputLabel>
                            <Select                                  
                                label="subType"
                                name="subType"
                                value={formData.subType ?? ""}
                                onChange={handleOnChange}
                                disabled={
                                    (formState.disabled) ||
                                    (!!formData.id)                               
                                }
                            >       
                                <MenuItem key={1} value={"file"}>File</MenuItem>
                                <MenuItem key={2} value={"files"}>Files</MenuItem>
                                <MenuItem key={3} value={"image"}>Image</MenuItem>
                                <MenuItem key={4} value={"images"}>Images</MenuItem>
                            </Select>
                        </FormControl>
                    </Case>

                    <Case value={"list"}>
                        <List data={formData} setData={setFormData} type={type} formState={formState} setFormState={setFormState} onError={handleError}></List>
                    </Case>             
                </Switch>

                {/* TYPE OPTIONS */}
                <Switch condition={formData.type}>
                    <Case value={"stringformat"}>
                        <TextField sx={{mb: 1}}                        
                            margin="dense"
                            id="format"
                            label="Format"
                            fullWidth                    
                            variant="outlined"                        
                            autoComplete="off"
                            value={formData.format ?? ""}
                            disabled={formState.disabled}
                            onChange={handleOnChange}                    
                        />
                    </Case>

                    <Case value={"select"}>
                        <TextField
                            fullWidth
                            id="config.options"
                            label="Options"
                            multiline
                            rows={20}
                            // value={formData?.config?.options ?? []}
                            value={test()}
                            // onChange={handleOnChange}
                            onChange={(event) => {                        
                                try {                                    
                                    handleOnChange({target: {name: "config.options", value: JSON.parse(event.target.value)}})    
                                } catch (error) {
                                    handleOnChange({target: {name: "config.options", value: event.target.value}})
                                }
        
                                
                            } }
                            
                            disabled={formState.disabled}                    
                        />
                    </Case>                  

                    <Case value={"upload"}>
                        <SimpleUIChipBox 
                            name="mimetypes" 
                            label={"Mimetypes"} 
                            freeSolo
                            value={formData.mimetypes ?? []}
                            disabled={formState.disabled}                                                        
                            onChange={handleOnChange}
                        />
                    </Case>

                    <Case value={"user"}>
                        <FormControl sx={{mt: 1, mb: 1}}
                            fullWidth 
                            required 
                        >   
                            <InputLabel>Datasource: NAME</InputLabel>
                            <Select
                                label="Datasource: NAME"
                                name="dataSource.name"
                                value={formData.dataSource?.name ?? ""}
                                onChange={handleOnChange}
                                disabled={(
                                    (formState.disabled) ||
                                    (!!formData.id)                               
                                )}
                            >                                       
                                {Object.keys(type.fields || {}).map(function (key, i)  {
                                    if (
                                        (type.fields[key].type == "textfield") || 
                                        (type.fields[key].type == "stringformat")
                                    ) {
                                        return (
                                            <MenuItem key={i} value={key}>{type.fields[key].label}</MenuItem>
                                        )
                                    }
                                })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{mt: 1, mb: 1}}
                            fullWidth 
                            required 
                        >   
                            <InputLabel>Datasource: EMAIL</InputLabel>
                            <Select
                                label="Datasource: NAME"
                                name="dataSource.email"
                                value={formData.dataSource?.email ?? ""}
                                onChange={handleOnChange}
                                disabled={(
                                    (formState.disabled) ||
                                    (!!formData.id)                               
                                )}
                            >    
                                                                
                                {Object.keys(type.fields || {}).map(function (key, i)  {
                                    if (
                                        (type.fields[key].type == "textfield") && 
                                        ((type.fields[key].subType || "") == "email") &&
                                        (type.fields[key].required) &&
                                        (type.fields[key].unique) &&
                                        (!checkIfDatasourceIsInUseByType(fieldId, "user", "email"))                                        
                                    ) {
                                        return (
                                            <MenuItem key={i} value={key}>{type.fields[key].label}</MenuItem>
                                        )
                                    }
                                })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{mt: 1, mb: 1}}
                            fullWidth 
                            required 
                        >   
                            <InputLabel>Usertype</InputLabel>
                            <Select
                                label="Usertype"
                                name="usertype"
                                value={formData.usertype ?? ""}
                                onChange={handleOnChange}
                                disabled={(
                                    (formState.disabled) ||
                                    (!!formData.id)                               
                                )}
                            >                                    
                                {userTypes.map(function (item, i)  {                                    
                                    return (
                                        <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Case>

                    {/* <Case value={"list"}>
                        <Switch condition={formData.subType}>
                            <Case value={"entities"}>
                                <FormControl sx={{mt: 1, mb: 1}}
                                    fullWidth 
                                    required 
                                >
                                    <InputLabel>EntityType</InputLabel>
                                    <Select                                  
                                        label="EntityType"
                                        name="config.allowedTypes"
                                        multiple
                                        value={formData?.config?.allowedTypes ?? []}
                                        onChange={handleOnChange}                        
                                        disabled={(                                            
                                            (formState.disabled)
                                        )}
                                    >       
                                        {entityTypes.map(function (item, i)  {                                    
                                            return (
                                                <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                            )
                                        })}                                        
                                    </Select>
                                </FormControl>
                                <FormControl sx={{mt: 1, mb: 1}}
                                    fullWidth 
                                    required 
                                >
                                    <InputLabel>Reference</InputLabel>
                                    <Select                                  
                                        label="Reference"
                                        name="config.ref"
                                        value={formData?.config?.ref ?? ""}
                                        onChange={handleOnChange}                        
                                        disabled={(                                            
                                            (formState.disabled)
                                        )}
                                    >       
                                        {entityTypes.map(function (item, i)  {                                    
                                            return (
                                                <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                            )
                                        })}                                        
                                    </Select>
                                </FormControl>
                            </Case>

                            <Case value={"json"}>
                                <TextField
                                    fullWidth
                                    id="config.jsonSchema"
                                    label="Schema"
                                    multiline
                                    rows={20}
                                    value={formData?.config?.jsonSchema ?? ""}
                                    onChange={handleOnChange}
                                    disabled={formState.disabled}                    
                                />
                            </Case>
                        </Switch>
                    </Case> */}
                </Switch>
            </Paper>
                                
            <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                    <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={handleOnClickMainButton}>
                        {formData.id ? "Apply" : "Add" }
                    </LoadingButton>       
                </Stack>                   
            </Box>                
        </React.Fragment>
    )
}