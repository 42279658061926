import React, { Fragment } from "react"
import { useBetween } from 'use-between'
import { Link, useLocation } from "react-router-dom"
import { useNavigate} from 'react-router-dom'
// import Path from 'path'

// MUI
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { Icon} from '@mui/material'

// import DynamicIcon from "./dynamicicon"


// MUI - ICONS
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import { useAuthState } from '../components/authProvider'   

// OTHER
// import pages from '../pages/pages'

const useDrawerState = () => {
    const [open, setOpen] = React.useState(true)
    const toggleDrawer = () => {        
        setOpen(!open)
    }
    return {
        open, toggleDrawer
    }
}

const useSharedDrawerState = () => useBetween(useDrawerState);

const drawerWidth = 230;

const DrawerStyled = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        // backgroundColor: "#1976d2",
        // color: "#ffffff",
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
)
  

function MenuItems(path, settings) {
    const location = useLocation();

    const isChildOf = (child, parent) => {
        // console.log (child)
        // console.log (parent)
        if (child === parent) return false
        let parentTokens = parent.split('/').filter(i => i.length)
        let childTokens = child.split('/').filter(i => i.length)
        return parentTokens.every((t, i) => childTokens[i] === t)
      }

    const activeRoute = (currentPath, path) => {        
        if (currentPath == path)
            return true

        if (currentPath == "/")
            return false

        return isChildOf(path, currentPath)
        // console.log (isChildOf(path, currentPath))     
        // return path === currentPath? true : false;
    }    

    return (        
        <React.Fragment>
            <ListItemButton component={Link} to={path} selected={activeRoute(path, location.pathname)}>
                <ListItemIcon>
                    <Icon>{settings.icon}</Icon>
                </ListItemIcon>    
                <ListItemText primary={settings.label} />    
            </ListItemButton>        
      </React.Fragment>
    )
}

function SideBarNavContent(props) {
    const { navigation, settings } = props
    const { open, toggleDrawer } = useSharedDrawerState()
    const { user, accessToken } = useAuthState()

    let test = navigation.sort((a, b) => a.navigation.sort - b.navigation.sort)

    return (
        <DrawerStyled variant="permanent" open={open}>
            <Toolbar sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}>
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                {test.map(({ path, navigation }) => (
                    navigation 
                    ?                     
                    MenuItems(path, navigation)        
                    :                     
                    ''
                ))}
            </List>
        </DrawerStyled>
    )
}

export default function SideBarNav(props) {
    const { pages, navigation, settings } = props

    return <SideBarNavContent pages={pages} navigation={navigation} settings={settings}/>
}

export function SideBarNavState() {
    return useSharedDrawerState ()
}

export const Width = drawerWidth