// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from "react-router-dom"
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {    
    Box,
    CircularProgress
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// import { isAdministrator, API_URL_PHONEZOLUTIONS, API_URL_SIMPLECRM } from '../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Render from './components/render'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const params = useParams()
    const navigate = useNavigate()

    const formStateDefault = {
        isInitializing: true        
    }

    const [formState, setFormState] = useState(formStateDefault)
    const [formData, setFormData] = useState()

    useEffect(() => {
        setFormData({typeId: props.typeId, entityId: ((params.entityId == "00000000-0000-0000-0000-000000000000") ? undefined : params.entityId) })
    }, [])

    useEffect(() => {
        setFormState({ ...formState, isInitializing: false })
    },[formData])

    const createCallback = (type, entity) => {
        // if (
        //     (props.typeId) && 
        //     (type?.navigation?.url)
        // ) {
        //     navigate(`${type.navigation.url}/${entity.id}`)
        // } else {
            navigate('/entities/'+ entity.id)
        // }
    }

    // const updateCallback = (entity) => {}

    const returnCallback = () => {
        navigate(-1)
    }

    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    } 

    return (
        <Render typeId={formData.typeId} entityId={formData.entityId} createCallback={createCallback} returnCallback={returnCallback}></Render>
    )
}
