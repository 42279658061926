// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI LAB ----
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Page from '../../components/gate/page'
import FormPasswordSet from '../../components/gate/formPasswordSet'
// ----------------------------------------------------------------------

export default function Main() {
    return (    
        <Page>
            <FormPasswordSet></FormPasswordSet>       
        </Page>
    )
}