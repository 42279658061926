// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    CircularProgress,
    Fade,
    Fab,
    Typography    
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Delete as RemoveIcon, 
    CloudDownload as DownloadIcon,
    CloudUpload as UploadIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

export default (props) => {
    const [preview, setPreview] = useState()

    useEffect(() => {
        if (props.data[props.fieldId]) {
            props.onGetPreview(props.data[props.fieldId])
            .then (result => {
                setPreview(result)
            }).catch(error => {
                props.onError (error)
            })            
        }
            
    }, [props.data[props.fieldId]])      
    
    return (
        <React.Fragment>                               
            <Box 
                display="flex" 
                position="relative" 
                justifyContent="center" 
                
                sx={{                 
                    alignItems: 'center', 
                    height: '200px', 
                    width: '200px', 
                    border: 1, 
                    borderRadius: 2, 
                    borderColor: '#bbbbbb',
                    overflow: 'hidden'
                }}>
                
                <Fade in={(props.uploadProgress > 0 && props.uploadProgress < 100)}>
                    <Box mt={3} position="absolute">
                        <CircularProgress variant="determinate" value={props.uploadProgress} />
                    </Box>
                </Fade>
                
                {!(props.data[props.fieldId])
                &&
                    <label htmlFor={`${props.fieldId}-file`}>
                        <Box m={1} position="absolute" left="0px" bottom="0px">
                            <Fab
                                component="span" 
                                size="small" 
                                color="success"                                     
                                disabled={(props.formState.locked || props.formState.disabled)}
                            >
                                <UploadIcon fontSize='small'/>
                            </Fab>
                        </Box>
                    </label>
                }

                {(props.data[props.fieldId])
                &&
                    <React.Fragment>                            
                        <Fade in={preview}>
                            <Box 
                                sx={{                                
                                    backgroundSize: "cover",
                                    backgroundImage: `url("${preview || ""}")`,                                
                                    backgroundRepeat: "no-repeat",                                
                                    height: "100%",
                                    width: "100%",                                    
                                }}                                    
                            />
                        </Fade>
                        
                        <Fade in={preview}>
                            <Box m={1} position="absolute" left="0px" bottom="0px">                                                        
                                <Fab 
                                    size="small" 
                                    color="primary" 
                                    
                                    onClick={() => {props.onDownloadFile (null)}}
                                    disabled={(props.formState.locked || props.formState.disabled || props.meta.fileNotFound)}
                                >
                                    <DownloadIcon fontSize='small'/>
                                </Fab>
                            </Box>
                        </Fade>
                        <Fade in={preview}>
                            <Box m={1} position="absolute" right="0px" bottom="0px">
                                <Fab 
                                    size="small" 
                                    color="error" 

                                    onClick={() => {props.onRemoveFile (null)}}
                                    disabled={(props.formState.locked || props.formState.disabled)}
                                >
                                    <RemoveIcon fontSize='small'/>
                                </Fab>
                            </Box>
                        </Fade>
                    </React.Fragment>
                }

                {/* <Box 
                    position="absolute" 
                    bottom="0px" 
                    left="0px" 
                    sx={{
                        width: "100%", 
                        height: "20%", 
                        backgroundColor: "rgba(0, 0, 0, .5)",
                    }}
                />              */}

                <Fade in={(props.formState.locked || props.formState.disabled)}>
                    <Box 
                        position="absolute" 
                        bottom="0px" 
                        left="0"
                        sx={{
                            height: "100%",
                            width: "100%",                                    
                            backgroundColor: "rgba(255, 255, 255, .5)"
                        }}                                    
                    />
                </Fade>                       

                {/* <Box m={1} position="absolute" bottom="0px">
                    <Typography color={'white'}>{props.type.fields[props.fieldId].title}</Typography>
                </Box> */}
            </Box>               
        </React.Fragment>
    )    
}