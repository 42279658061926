// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Checkbox,
    FormLabel,
    FormControl,
    FormControlLabel,
    FormGroup
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

export default (props) => {
    const handleOnChange = (event) => {
        props.onChange({target: {value: {...props.data[props.fieldId], [event.target.name]: event.target.checked}}})
    }

    return (
        <FormControl                       
            required={props.type.fields[props.fieldId].required}
            disabled={(props.formState.locked || props.formState.disabled)}
        >      
            <FormLabel id={`${props.type.fields[props.fieldId]}-label`}>
                {props.type.fields[props.fieldId].title}
            </FormLabel>            

            <FormGroup
                row={true}
            >
                {props.type.fields[props.fieldId].options.map((option, i) => {
                    return (
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    
                                    name={option.value}
                                    checked={props.data[props.fieldId][option.value]}                                    
                                    onChange={handleOnChange}
                                />
                            }
                            label={option.label}
                        />
                    )
                })}
            </FormGroup>
        </FormControl>            
    )    
}