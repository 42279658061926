// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Drawer,
    CircularProgress
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

export default (props) => {
    const {state, setState} = props

    // const handleClose = (event, reason) => {
        
    //     if (reason && reason == "backdropClick") 
    //         return 

    //     if (reason && reason == "escapeKeyDown") 
    //         return
        
    //     setState({...state, open: false})        
    // }

    return (
        <Drawer open={state.open} anchor={'right'} style={{ zIndex: 1201 }} 
            PaperProps={{
            sx: { width: "600px" },
          }}
        >
            
            <Box sx={{ p: 2, width: '100%', minHeight: '100%' }} role="permanent">
                {React.cloneElement(props.children, {...props, drawerState: state, setDrawerState: setState})}
            </Box>       
        </Drawer>
    )
}